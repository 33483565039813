<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="211" height="69" viewBox="0 0 211 69" fill="none">
    <g clip-path="url(#clip0_3252_31813)">
      <mask id="mask0_3252_31813" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="138" y="0" width="73"
            height="69">
        <path
            d="M156.062 6.59063C150.992 9.25063 145.752 12.0106 139.912 12.7306C139.342 12.8006 138.852 13.1106 138.542 13.5606C138.222 14.0306 138.132 14.6106 138.282 15.1506C143.132 32.5206 150.132 46.0206 173.242 67.6106C173.352 67.7106 173.472 67.7906 173.562 67.8406L173.872 67.9906C174.092 68.0706 174.312 68.1106 174.552 68.1106C174.802 68.1106 175.052 68.0606 175.242 67.9806L175.452 67.8906L175.542 67.8306C175.622 67.7806 175.742 67.7106 175.872 67.5906C198.962 46.0106 205.972 32.5106 210.822 15.1406C210.972 14.6006 210.882 14.0206 210.572 13.5606C210.262 13.0906 209.762 12.7806 209.202 12.7106C203.352 11.9906 198.112 9.23063 193.032 6.56063C186.992 3.39063 181.302 0.390625 174.562 0.390625C167.822 0.390625 162.122 3.39063 156.072 6.57063"
            fill="white"/>
      </mask>
      <g mask="url(#mask0_3252_31813)">
        <path d="M121.846 16.7011L104.281 34.2656L174.554 104.538L192.118 86.9734L121.846 16.7011Z" fill="#401617"/>
        <path d="M122.12 16.4254L121.844 16.7012L192.116 86.9734L192.392 86.6977L122.12 16.4254Z" fill="#401617"/>
        <path d="M122.401 16.1539L122.125 16.4297L192.397 86.702L192.673 86.4262L122.401 16.1539Z" fill="#401617"/>
        <path d="M122.666 15.8746L122.391 16.1504L192.663 86.4227L192.939 86.1469L122.666 15.8746Z" fill="#431B19"/>
        <path d="M122.948 15.607L122.672 15.8828L192.944 86.1551L193.22 85.8793L122.948 15.607Z" fill="#461F1B"/>
        <path d="M123.213 15.3277L122.938 15.6035L193.21 85.8758L193.486 85.6L123.213 15.3277Z" fill="#49231D"/>
        <path d="M123.495 15.0582L123.219 15.334L193.491 85.6063L193.767 85.3305L123.495 15.0582Z" fill="#4C271F"/>
        <path d="M123.76 14.7809L123.484 15.0566L193.757 85.3289L194.032 85.0531L123.76 14.7809Z" fill="#4F2B21"/>
        <path d="M124.041 14.5016L123.766 14.7773L194.038 85.0496L194.314 84.7738L124.041 14.5016Z" fill="#532F22"/>
        <path d="M124.315 14.2399L124.039 14.5156L194.311 84.7879L194.587 84.5121L124.315 14.2399Z" fill="#563324"/>
        <path d="M124.596 13.9527L124.32 14.2285L194.593 84.5008L194.868 84.225L124.596 13.9527Z" fill="#593726"/>
        <path d="M124.87 13.6813L124.594 13.957L194.866 84.2293L195.142 83.9535L124.87 13.6813Z" fill="#5D3B27"/>
        <path d="M125.135 13.4117L124.859 13.6875L195.132 83.9598L195.407 83.684L125.135 13.4117Z" fill="#604028"/>
        <path d="M125.416 13.1344L125.141 13.4102L195.413 83.6824L195.689 83.4067L125.416 13.1344Z" fill="#64442A"/>
        <path d="M125.682 12.8551L125.406 13.1309L195.679 83.4031L195.954 83.1274L125.682 12.8551Z" fill="#67482B"/>
        <path d="M125.963 12.5856L125.688 12.8613L195.96 83.1336L196.236 82.8578L125.963 12.5856Z" fill="#6B4C2D"/>
        <path d="M126.229 12.3063L125.953 12.582L196.225 82.8543L196.501 82.5785L126.229 12.3063Z" fill="#6C4D2D"/>
        <path d="M126.51 12.0387L126.234 12.3145L196.507 82.5867L196.782 82.311L126.51 12.0387Z" fill="#6D4E2E"/>
        <path d="M126.784 11.7652L126.508 12.041L196.78 82.3133L197.056 82.0375L126.784 11.7652Z" fill="#6E502E"/>
        <path d="M127.065 11.4801L126.789 11.7559L197.061 82.0281L197.337 81.7524L127.065 11.4801Z" fill="#6F512F"/>
        <path d="M127.338 11.2184L127.062 11.4941L197.335 81.7664L197.611 81.4906L127.338 11.2184Z" fill="#70522F"/>
        <path d="M127.604 10.9391L127.328 11.2148L197.6 81.4871L197.876 81.2113L127.604 10.9391Z" fill="#715330"/>
        <path d="M127.885 10.6598L127.609 10.9355L197.882 81.2078L198.157 80.932L127.885 10.6598Z" fill="#725431"/>
        <path d="M128.159 10.3922L127.883 10.668L198.155 80.9402L198.431 80.6645L128.159 10.3922Z" fill="#735531"/>
        <path d="M128.432 10.1129L128.156 10.3887L198.429 80.6609L198.704 80.3852L128.432 10.1129Z" fill="#745632"/>
        <path d="M128.698 9.84337L128.422 10.1191L198.694 80.3914L198.97 80.1156L128.698 9.84337Z" fill="#755732"/>
        <path d="M128.987 9.56407L128.711 9.83984L198.983 80.1121L199.259 79.8363L128.987 9.56407Z" fill="#765833"/>
        <path d="M129.245 9.28673L128.969 9.5625L199.241 79.8348L199.517 79.559L129.245 9.28673Z" fill="#775934"/>
        <path d="M129.534 9.0172L129.258 9.29297L199.53 79.5652L199.806 79.2895L129.534 9.0172Z" fill="#785B34"/>
        <path d="M129.807 8.74571L129.531 9.02148L199.804 79.2938L200.079 79.018L129.807 8.74571Z" fill="#7A5C35"/>
        <path d="M130.088 8.4586L129.812 8.73438L200.085 79.0066L200.361 78.7309L130.088 8.4586Z" fill="#7B5D36"/>
        <path d="M130.354 8.19689L130.078 8.47266L200.35 78.7449L200.626 78.4692L130.354 8.19689Z" fill="#7C5E36"/>
        <path d="M130.627 7.91758L130.352 8.19336L200.624 78.4656L200.9 78.1899L130.627 7.91758Z" fill="#7D5F37"/>
        <path d="M130.901 7.64024L130.625 7.91602L200.897 78.1883L201.173 77.9125L130.901 7.64024Z" fill="#7E6038"/>
        <path d="M131.166 7.37071L130.891 7.64648L201.163 77.9188L201.439 77.643L131.166 7.37071Z" fill="#7F6138"/>
        <path d="M131.455 7.09142L131.18 7.36719L201.452 77.6395L201.728 77.3637L131.455 7.09142Z" fill="#806339"/>
        <path d="M131.713 6.82189L131.438 7.09766L201.71 77.3699L201.986 77.0942L131.713 6.82189Z" fill="#81643A"/>
        <path d="M132.01 6.54454L131.734 6.82031L202.007 77.0926L202.282 76.8168L132.01 6.54454Z" fill="#83653B"/>
        <path d="M132.276 6.2711L132 6.54688L202.272 76.8191L202.548 76.5434L132.276 6.2711Z" fill="#84663C"/>
        <path d="M132.557 5.99571L132.281 6.27148L202.554 76.5438L202.829 76.268L132.557 5.99571Z" fill="#85673C"/>
        <path d="M132.823 5.72423L132.547 6L202.819 76.2723L203.095 75.9965L132.823 5.72423Z" fill="#86693D"/>
        <path d="M133.096 5.44493L132.82 5.7207L203.093 75.993L203.368 75.7172L133.096 5.44493Z" fill="#876A3E"/>
        <path d="M133.37 5.17734L133.094 5.45312L203.366 75.7254L203.642 75.4496L133.37 5.17734Z" fill="#886B3F"/>
        <path d="M133.651 4.89806L133.375 5.17383L203.647 75.4461L203.923 75.1703L133.651 4.89806Z" fill="#896C40"/>
        <path d="M133.924 4.62853L133.648 4.9043L203.921 75.1766L204.196 74.9008L133.924 4.62853Z" fill="#8B6D40"/>
        <path d="M134.182 4.34923L133.906 4.625L204.179 74.8973L204.454 74.6215L134.182 4.34923Z" fill="#8C6F41"/>
        <path d="M134.479 4.06993L134.203 4.3457L204.475 74.618L204.751 74.3422L134.479 4.06993Z" fill="#8D7042"/>
        <path d="M134.737 3.80235L134.461 4.07812L204.733 74.3504L205.009 74.0746L134.737 3.80235Z" fill="#8E7143"/>
        <path d="M135.026 3.52306L134.75 3.79883L205.022 74.0711L205.298 73.7953L135.026 3.52306Z" fill="#907344"/>
        <path d="M135.291 3.25157L135.016 3.52734L205.288 73.7996L205.564 73.5238L135.291 3.25157Z" fill="#917445"/>
        <path d="M135.573 2.97423L135.297 3.25L205.569 73.5223L205.845 73.2465L135.573 2.97423Z" fill="#927546"/>
        <path d="M135.846 2.70274L135.57 2.97852L205.843 73.2508L206.118 72.975L135.846 2.70274Z" fill="#937747"/>
        <path d="M136.12 2.43517L135.844 2.71094L206.116 72.9832L206.392 72.7074L136.12 2.43517Z" fill="#957848"/>
        <path d="M136.401 2.15587L136.125 2.43164L206.397 72.7039L206.673 72.4281L136.401 2.15587Z" fill="#967949"/>
        <path d="M136.659 1.87657L136.383 2.15234L206.655 72.4246L206.931 72.1488L136.659 1.87657Z" fill="#977B4A"/>
        <path d="M136.948 1.60704L136.672 1.88281L206.944 72.1551L207.22 71.8793L136.948 1.60704Z" fill="#987C4B"/>
        <path d="M137.213 1.3297L136.938 1.60547L207.21 71.8777L207.486 71.602L137.213 1.3297Z" fill="#997D4C"/>
        <path d="M137.495 1.0504L137.219 1.32617L207.491 71.5984L207.767 71.3227L137.495 1.0504Z" fill="#9B7F4D"/>
        <path d="M137.76 0.788682L137.484 1.06445L207.757 71.3367L208.032 71.061L137.76 0.788682Z" fill="#9C804E"/>
        <path d="M138.041 0.501573L137.766 0.777344L208.038 71.0496L208.314 70.7738L138.041 0.501573Z" fill="#9D824F"/>
        <path d="M138.315 0.230088L138.039 0.505859L208.311 70.7781L208.587 70.5024L138.315 0.230088Z" fill="#9E8350"/>
        <path d="M138.588 -0.0394431L138.312 0.236328L208.585 70.5086L208.861 70.2328L138.588 -0.0394431Z"
              fill="#A08451"/>
        <path d="M138.87 -0.316787L138.594 -0.0410156L208.866 70.2313L209.142 69.9555L138.87 -0.316787Z"
              fill="#A18652"/>
        <path d="M139.127 -0.586318L138.852 -0.310547L209.124 69.9617L209.4 69.686L139.127 -0.586318Z" fill="#A28753"/>
        <path d="M139.416 -0.865615L139.141 -0.589844L209.413 69.6824L209.689 69.4067L139.416 -0.865615Z"
              fill="#A48954"/>
        <path d="M139.682 -1.14491L139.406 -0.869141L209.679 69.4031L209.954 69.1274L139.682 -1.14491Z" fill="#A58A55"/>
        <path d="M139.963 -1.41249L139.688 -1.13672L209.96 69.1356L210.236 68.8598L139.963 -1.41249Z" fill="#A68C58"/>
        <path d="M140.237 -1.68593L139.961 -1.41016L210.233 68.8621L210.509 68.5863L140.237 -1.68593Z" fill="#A88E5A"/>
        <path d="M140.51 -1.97108L140.234 -1.69531L210.507 68.577L210.782 68.3012L140.51 -1.97108Z" fill="#A98F5D"/>
        <path d="M140.784 -2.2328L140.508 -1.95703L210.78 68.3152L211.056 68.0395L140.784 -2.2328Z" fill="#AA915F"/>
        <path d="M141.057 -2.5121L140.781 -2.23633L211.054 68.0359L211.329 67.7602L141.057 -2.5121Z" fill="#AC9362"/>
        <path d="M141.338 -2.78163L141.062 -2.50586L211.335 67.7664L211.611 67.4906L141.338 -2.78163Z" fill="#AD9564"/>
        <path d="M141.604 -3.05897L141.328 -2.7832L211.6 67.4891L211.876 67.2133L141.604 -3.05897Z" fill="#AF9667"/>
        <path d="M141.885 -3.33827L141.609 -3.0625L211.882 67.2098L212.157 66.934L141.885 -3.33827Z" fill="#B09869"/>
        <path d="M142.151 -3.6078L141.875 -3.33203L212.147 66.9402L212.423 66.6645L142.151 -3.6078Z" fill="#B19A6C"/>
        <path d="M142.432 -3.8871L142.156 -3.61133L212.429 66.6609L212.704 66.3852L142.432 -3.8871Z" fill="#B39C6E"/>
        <path d="M142.698 -4.16444L142.422 -3.88867L212.694 66.3836L212.97 66.1078L142.698 -4.16444Z" fill="#B49D71"/>
        <path d="M142.987 -4.43397L142.711 -4.1582L212.983 66.1141L213.259 65.8383L142.987 -4.43397Z" fill="#B59F73"/>
        <path d="M143.26 -4.70546L142.984 -4.42969L213.257 65.8426L213.532 65.5668L143.26 -4.70546Z" fill="#B7A176"/>
        <path d="M143.534 -4.99257L143.258 -4.7168L213.53 65.5555L213.806 65.2797L143.534 -4.99257Z" fill="#B8A379"/>
        <path d="M143.807 -5.25429L143.531 -4.97852L213.804 65.2938L214.079 65.018L143.807 -5.25429Z" fill="#BAA57B"/>
        <path d="M144.073 -5.53358L143.797 -5.25781L214.069 65.0145L214.345 64.7387L144.073 -5.53358Z" fill="#BBA67E"/>
        <path d="M144.354 -5.80116L144.078 -5.52539L214.35 64.7469L214.626 64.4711L144.354 -5.80116Z" fill="#BDA881"/>
        <path d="M144.62 -6.08047L144.344 -5.80469L214.616 64.4676L214.892 64.1918L144.62 -6.08047Z" fill="#BEAA83"/>
        <path d="M144.901 -6.35976L144.625 -6.08398L214.897 64.1883L215.173 63.9125L144.901 -6.35976Z" fill="#BFAC86"/>
        <path d="M145.166 -6.62929L144.891 -6.35352L215.163 63.9188L215.439 63.643L145.166 -6.62929Z" fill="#C1AE89"/>
        <path d="M145.455 -6.90663L145.18 -6.63086L215.452 63.6414L215.728 63.3656L145.455 -6.90663Z" fill="#C2B08C"/>
        <path d="M145.729 -7.18007L145.453 -6.9043L215.725 63.368L216.001 63.0922L145.729 -7.18007Z" fill="#C4B28F"/>
        <path d="M146.01 -7.45546L145.734 -7.17969L216.007 63.0926L216.282 62.8168L146.01 -7.45546Z" fill="#C5B491"/>
        <path d="M146.276 -7.72694L146 -7.45117L216.272 62.8211L216.548 62.5453L146.276 -7.72694Z" fill="#C7B594"/>
        <path d="M146.549 -7.99647L146.273 -7.7207L216.546 62.5516L216.821 62.2758L146.549 -7.99647Z" fill="#C8B797"/>
        <path d="M146.823 -8.27382L146.547 -7.99805L216.819 62.2742L217.095 61.9985L146.823 -8.27382Z" fill="#C9B99A"/>
        <path d="M147.088 -8.55311L146.812 -8.27734L217.085 61.9949L217.361 61.7192L147.088 -8.55311Z" fill="#CBBB9D"/>
        <path d="M147.37 -8.82266L147.094 -8.54688L217.366 61.7254L217.642 61.4496L147.37 -8.82266Z" fill="#CCBDA0"/>
        <path d="M147.635 -9.10194L147.359 -8.82617L217.632 61.4461L217.907 61.1703L147.635 -9.10194Z" fill="#CEBFA3"/>
        <path d="M147.924 -9.38124L147.648 -9.10547L217.921 61.1668L218.196 60.891L147.924 -9.38124Z" fill="#CEC0A4"/>
        <path d="M148.182 -9.64882L147.906 -9.37305L218.179 60.8992L218.454 60.6235L148.182 -9.64882Z" fill="#CDBEA2"/>
        <path d="M148.479 -9.92811L148.203 -9.65234L218.475 60.6199L218.751 60.3442L148.479 -9.92811Z" fill="#CCBCA0"/>
        <path d="M148.745 -10.1898L148.469 -9.91406L218.741 60.3582L219.017 60.0824L148.745 -10.1898Z" fill="#CABA9E"/>
        <path d="M149.026 -10.4769L148.75 -10.2012L219.022 60.0711L219.298 59.7953L149.026 -10.4769Z" fill="#C9B99C"/>
        <path d="M149.291 -10.7484L149.016 -10.4727L219.288 59.7996L219.564 59.5238L149.291 -10.7484Z" fill="#C8B79A"/>
        <path d="M149.573 -11.016L149.297 -10.7402L219.569 59.532L219.845 59.2563L149.573 -11.016Z" fill="#C6B598"/>
        <path d="M149.846 -11.2953L149.57 -11.0195L219.843 59.2527L220.118 58.977L149.846 -11.2953Z" fill="#C5B396"/>
        <path d="M150.104 -11.5746L149.828 -11.2988L220.1 58.9734L220.376 58.6977L150.104 -11.5746Z" fill="#C3B194"/>
        <path d="M150.401 -11.8441L150.125 -11.5684L220.397 58.7039L220.673 58.4281L150.401 -11.8441Z" fill="#C2AF92"/>
        <path d="M150.659 -12.1215L150.383 -11.8457L220.655 58.4266L220.931 58.1508L150.659 -12.1215Z" fill="#C0AD90"/>
        <path d="M150.948 -12.4008L150.672 -12.125L220.944 58.1473L221.22 57.8715L150.948 -12.4008Z" fill="#BFAB8E"/>
        <path d="M151.213 -12.6625L150.938 -12.3867L221.21 57.8856L221.486 57.6098L151.213 -12.6625Z" fill="#BEAA8C"/>
        <path d="M151.495 -12.9496L151.219 -12.6738L221.491 57.5984L221.767 57.3227L151.495 -12.9496Z" fill="#BCA88A"/>
        <path d="M151.76 -13.2113L151.484 -12.9355L221.757 57.3367L222.032 57.061L151.76 -13.2113Z" fill="#BBA688"/>
        <path d="M152.041 -13.4906L151.766 -13.2148L222.038 57.0574L222.314 56.7817L152.041 -13.4906Z" fill="#B9A486"/>
        <path d="M152.315 -13.768L152.039 -13.4922L222.311 56.7801L222.587 56.5043L152.315 -13.768Z" fill="#B8A284"/>
        <path d="M152.573 -14.0375L152.297 -13.7617L222.569 56.5106L222.845 56.2348L152.573 -14.0375Z" fill="#B6A082"/>
        <path d="M152.87 -14.3168L152.594 -14.041L222.866 56.2313L223.142 55.9555L152.87 -14.3168Z" fill="#B59E80"/>
        <path d="M153.127 -14.5961L152.852 -14.3203L223.124 55.952L223.4 55.6762L153.127 -14.5961Z" fill="#B49D7E"/>
        <path d="M153.416 -14.8637L153.141 -14.5879L223.413 55.6844L223.689 55.4086L153.416 -14.8637Z" fill="#B29B7C"/>
        <path d="M153.682 -15.1371L153.406 -14.8613L223.679 55.4109L223.954 55.1352L153.682 -15.1371Z" fill="#B1997A"/>
        <path d="M153.963 -15.4125L153.688 -15.1367L223.96 55.1356L224.236 54.8598L153.963 -15.4125Z" fill="#AF9778"/>
        <path d="M154.237 -15.684L153.961 -15.4082L224.233 54.8641L224.509 54.5883L154.237 -15.684Z" fill="#AE9576"/>
        <path d="M154.51 -15.9633L154.234 -15.6875L224.507 54.5848L224.782 54.309L154.51 -15.9633Z" fill="#AD9474"/>
        <path d="M154.784 -16.2328L154.508 -15.957L224.78 54.3152L225.056 54.0395L154.784 -16.2328Z" fill="#AB9272"/>
        <path d="M155.057 -16.5101L154.781 -16.2344L225.054 54.0379L225.329 53.7621L155.057 -16.5101Z" fill="#AA9070"/>
        <path d="M155.338 -16.7894L155.062 -16.5137L225.335 53.7586L225.611 53.4828L155.338 -16.7894Z" fill="#A88E6F"/>
        <path d="M155.596 -17.059L155.32 -16.7832L225.593 53.4891L225.868 53.2133L155.596 -17.059Z" fill="#A78C6D"/>
        <path d="M155.885 -17.3383L155.609 -17.0625L225.882 53.2098L226.157 52.934L155.885 -17.3383Z" fill="#A58B6B"/>
        <path d="M156.151 -17.6156L155.875 -17.3398L226.147 52.9324L226.423 52.6567L156.151 -17.6156Z" fill="#A48969"/>
        <path d="M156.432 -17.8851L156.156 -17.6094L226.429 52.6629L226.704 52.3871L156.432 -17.8851Z" fill="#A38767"/>
        <path d="M156.705 -18.1566L156.43 -17.8809L226.702 52.3914L226.978 52.1156L156.705 -18.1566Z" fill="#A18566"/>
        <path d="M156.987 -18.434L156.711 -18.1582L226.983 52.1141L227.259 51.8383L156.987 -18.434Z" fill="#A08464"/>
        <path d="M157.26 -18.7055L156.984 -18.4297L227.257 51.8426L227.532 51.5668L157.26 -18.7055Z" fill="#9E8262"/>
        <path d="M157.526 -18.9848L157.25 -18.709L227.522 51.5633L227.798 51.2875L157.526 -18.9848Z" fill="#9D8060"/>
        <path d="M157.807 -19.2523L157.531 -18.9766L227.804 51.2957L228.079 51.0199L157.807 -19.2523Z" fill="#9C7F5F"/>
        <path d="M158.073 -19.5316L157.797 -19.2559L228.069 51.0164L228.345 50.7406L158.073 -19.5316Z" fill="#9A7D5D"/>
        <path d="M158.354 -19.8109L158.078 -19.5352L228.35 50.7371L228.626 50.4613L158.354 -19.8109Z" fill="#997B5B"/>
        <path d="M158.62 -20.0805L158.344 -19.8047L228.616 50.4676L228.892 50.1918L158.62 -20.0805Z" fill="#987959"/>
        <path d="M158.901 -20.3578L158.625 -20.082L228.897 50.1902L229.173 49.9145L158.901 -20.3578Z" fill="#967858"/>
        <path d="M159.174 -20.6195L158.898 -20.3438L229.171 49.9285L229.446 49.6527L159.174 -20.6195Z" fill="#957656"/>
        <path d="M159.455 -20.9066L159.18 -20.6309L229.452 49.6414L229.728 49.3656L159.455 -20.9066Z" fill="#947555"/>
        <path d="M159.729 -21.1781L159.453 -20.9023L229.725 49.3699L230.001 49.0942L159.729 -21.1781Z" fill="#947353"/>
        <path d="M159.995 -21.4476L159.719 -21.1719L229.991 49.1004L230.267 48.8246L159.995 -21.4476Z" fill="#937151"/>
        <path d="M160.276 -21.725L160 -21.4492L230.272 48.8231L230.548 48.5473L160.276 -21.725Z" fill="#926F50"/>
        <path d="M160.541 -22.0043L160.266 -21.7285L230.538 48.5438L230.814 48.268L160.541 -22.0043Z" fill="#916E4E"/>
        <path d="M160.823 -22.2738L160.547 -21.998L230.819 48.2742L231.095 47.9985L160.823 -22.2738Z" fill="#906C4C"/>
        <path d="M161.088 -22.5531L160.812 -22.2773L231.085 47.9949L231.361 47.7192L161.088 -22.5531Z" fill="#8F6A4B"/>
        <path d="M161.37 -22.8207L161.094 -22.5449L231.366 47.7273L231.642 47.4516L161.37 -22.8207Z" fill="#8F6949"/>
        <path d="M161.651 -23.0941L161.375 -22.8184L231.647 47.4539L231.923 47.1781L161.651 -23.0941Z" fill="#8E6747"/>
        <path d="M161.924 -23.3793L161.648 -23.1035L231.921 47.1688L232.196 46.893L161.924 -23.3793Z" fill="#8D6646"/>
        <path d="M162.198 -23.641L161.922 -23.3652L232.194 46.907L232.47 46.6313L162.198 -23.641Z" fill="#8C6444"/>
        <path d="M162.463 -23.9203L162.188 -23.6445L232.46 46.6277L232.736 46.352L162.463 -23.9203Z" fill="#8B6243"/>
        <path d="M162.745 -24.1996L162.469 -23.9238L232.741 46.3484L233.017 46.0727L162.745 -24.1996Z" fill="#8A6141"/>
        <path d="M163.018 -24.4672L162.742 -24.1914L233.014 46.0809L233.29 45.8051L163.018 -24.4672Z" fill="#8A5F3F"/>
        <path d="M163.291 -24.7465L163.016 -24.4707L233.288 45.8016L233.564 45.5258L163.291 -24.7465Z" fill="#895E3E"/>
        <path d="M163.557 -25.0258L163.281 -24.75L233.554 45.5223L233.829 45.2465L163.557 -25.0258Z" fill="#885C3C"/>
        <path d="M163.846 -25.2953L163.57 -25.0195L233.843 45.2527L234.118 44.977L163.846 -25.2953Z" fill="#875A3B"/>
        <path d="M164.104 -25.5726L163.828 -25.2969L234.1 44.9754L234.376 44.6996L164.104 -25.5726Z" fill="#865939"/>
        <path d="M164.401 -25.8422L164.125 -25.5664L234.397 44.7059L234.673 44.4301L164.401 -25.8422Z" fill="#855738"/>
        <path d="M164.666 -26.1137L164.391 -25.8379L234.663 44.4344L234.939 44.1586L164.666 -26.1137Z" fill="#855636"/>
        <path d="M164.948 -26.4008L164.672 -26.125L234.944 44.1473L235.22 43.8715L164.948 -26.4008Z" fill="#845434"/>
        <path d="M165.213 -26.6625L164.938 -26.3867L235.21 43.8856L235.486 43.6098L165.213 -26.6625Z" fill="#835333"/>
        <path d="M165.487 -26.9418L165.211 -26.666L235.483 43.6063L235.759 43.3305L165.487 -26.9418Z" fill="#825131"/>
        <path d="M165.76 -27.2191L165.484 -26.9434L235.757 43.3289L236.032 43.0531L165.76 -27.2191Z" fill="#815030"/>
        <path d="M174.548 -35.9952L165.766 -27.2129L236.038 43.0594L244.82 34.2771L174.548 -35.9952Z" fill="#814F2F"/>
      </g>
      <mask id="mask1_3252_31813" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="143" y="9" width="63"
            height="57">
        <path
            d="M143.48 18.3209L143.32 18.3709C147.68 33.7709 153.93 45.8409 174.58 65.1709C195.21 45.8509 201.46 33.7809 205.78 18.2809L205.69 18.2609L205.66 18.3109C205.49 18.2209 187.98 9.21094 174.57 9.21094C161.15 9.21094 143.65 18.2209 143.48 18.3109"
            fill="white"/>
      </mask>
      <g mask="url(#mask1_3252_31813)">
        <path d="M205.779 9.23047H205.539V65.1805H205.779V9.23047Z" fill="#401617"/>
        <path d="M205.529 9.23047H205.289V65.1805H205.529V9.23047Z" fill="#401617"/>
        <path d="M205.287 9.23047H205.047V65.1805H205.287V9.23047Z" fill="#411918"/>
        <path d="M205.053 9.23047H204.812V65.1805H205.053V9.23047Z" fill="#431B1A"/>
        <path d="M204.803 9.23047H204.562V65.1805H204.803V9.23047Z" fill="#451E1C"/>
        <path d="M204.56 9.23047H204.32V65.1805H204.56V9.23047Z" fill="#47201E"/>
        <path d="M204.31 9.23047H204.07V65.1805H204.31V9.23047Z" fill="#492320"/>
        <path d="M204.068 9.23047H203.828V65.1805H204.068V9.23047Z" fill="#4B2522"/>
        <path d="M203.834 9.23047H203.594V65.1805H203.834V9.23047Z" fill="#4D2823"/>
        <path d="M203.584 9.23047H203.344V65.1805H203.584V9.23047Z" fill="#4F2A25"/>
        <path d="M203.342 9.23047H203.102V65.1805H203.342V9.23047Z" fill="#512D27"/>
        <path d="M203.092 9.23047H202.852V65.1805H203.092V9.23047Z" fill="#532F29"/>
        <path d="M202.849 9.23047H202.609V65.1805H202.849V9.23047Z" fill="#55312B"/>
        <path d="M202.615 9.23047H202.375V65.1805H202.615V9.23047Z" fill="#57342D"/>
        <path d="M202.365 9.23047H202.125V65.1805H202.365V9.23047Z" fill="#59362F"/>
        <path d="M202.115 9.23047H201.875V65.1805H202.115V9.23047Z" fill="#5B3931"/>
        <path d="M201.865 9.23047H201.625V65.1805H201.865V9.23047Z" fill="#5D3B33"/>
        <path d="M201.631 9.23047H201.391V65.1805H201.631V9.23047Z" fill="#5F3E35"/>
        <path d="M201.388 9.23047H201.148V65.1805H201.388V9.23047Z" fill="#614137"/>
        <path d="M201.138 9.23047H200.898V65.1805H201.138V9.23047Z" fill="#644339"/>
        <path d="M200.904 9.23047H200.664V65.1805H200.904V9.23047Z" fill="#66463C"/>
        <path d="M200.654 9.23047H200.414V65.1805H200.654V9.23047Z" fill="#68483E"/>
        <path d="M200.412 9.23047H200.172V65.1805H200.412V9.23047Z" fill="#6A4B40"/>
        <path d="M200.17 9.23047H199.93V65.1805H200.17V9.23047Z" fill="#6D4D42"/>
        <path d="M199.92 9.23047H199.68V65.1805H199.92V9.23047Z" fill="#6F5044"/>
        <path d="M199.677 9.23047H199.438V65.1805H199.677V9.23047Z" fill="#725346"/>
        <path d="M199.427 9.23047H199.188V65.1805H199.427V9.23047Z" fill="#745549"/>
        <path d="M199.193 9.23047H198.953V65.1805H199.193V9.23047Z" fill="#77584B"/>
        <path d="M198.951 9.23047H198.711V65.1805H198.951V9.23047Z" fill="#795B4D"/>
        <path d="M198.701 9.23047H198.461V65.1805H198.701V9.23047Z" fill="#7C5E50"/>
        <path d="M198.459 9.23047H198.219V65.1805H198.459V9.23047Z" fill="#7E6152"/>
        <path d="M198.209 9.23047H197.969V65.1805H198.209V9.23047Z" fill="#816455"/>
        <path d="M197.967 9.23047H197.727V65.1805H197.967V9.23047Z" fill="#846757"/>
        <path d="M197.732 9.23047H197.492V65.1805H197.732V9.23047Z" fill="#866A5A"/>
        <path d="M197.482 9.23047H197.242V65.1805H197.482V9.23047Z" fill="#896D5C"/>
        <path d="M197.24 9.23047H197V65.1805H197.24V9.23047Z" fill="#8C705F"/>
        <path d="M196.99 9.23047H196.75V65.1805H196.99V9.23047Z" fill="#8F7362"/>
        <path d="M196.756 9.23047H196.516V65.1805H196.756V9.23047Z" fill="#927765"/>
        <path d="M196.506 9.23047H196.266V65.1805H196.506V9.23047Z" fill="#947A68"/>
        <path d="M196.256 9.23047H196.016V65.1805H196.256V9.23047Z" fill="#977D6A"/>
        <path d="M196.021 9.23047H195.781V65.1805H196.021V9.23047Z" fill="#9A816D"/>
        <path d="M195.771 9.23047H195.531V65.1805H195.771V9.23047Z" fill="#9D8470"/>
        <path d="M195.529 9.23047H195.289V65.1805H195.529V9.23047Z" fill="#A08873"/>
        <path d="M195.287 9.23047H195.047V65.1805H195.287V9.23047Z" fill="#A38C77"/>
        <path d="M195.037 9.23047H194.797V65.1805H195.037V9.23047Z" fill="#A68F7A"/>
        <path d="M194.803 9.23047H194.562V65.1805H194.803V9.23047Z" fill="#AA937D"/>
        <path d="M194.553 9.23047H194.312V65.1805H194.553V9.23047Z" fill="#AD9780"/>
        <path d="M194.31 9.23047H194.07V65.1805H194.31V9.23047Z" fill="#B09B84"/>
        <path d="M194.068 9.23047H193.828V65.1805H194.068V9.23047Z" fill="#B39F87"/>
        <path d="M193.818 9.23047H193.578V65.1805H193.818V9.23047Z" fill="#B6A38B"/>
        <path d="M193.584 9.23047H193.344V65.1805H193.584V9.23047Z" fill="#B9A78E"/>
        <path d="M193.334 9.23047H193.094V65.1805H193.334V9.23047Z" fill="#BDAB92"/>
        <path d="M193.092 9.23047H192.852V65.1805H193.092V9.23047Z" fill="#C0AF95"/>
        <path d="M192.849 9.23047H192.609V65.1805H192.849V9.23047Z" fill="#C4B399"/>
        <path d="M192.599 9.23047H192.359V65.1805H192.599V9.23047Z" fill="#C7B79D"/>
        <path d="M192.365 9.23047H192.125V65.1805H192.365V9.23047Z" fill="#CBBBA0"/>
        <path d="M192.115 9.23047H191.875V65.1805H192.115V9.23047Z" fill="#CEC0A4"/>
        <path d="M191.865 9.23047H191.625V65.1805H191.865V9.23047Z" fill="#CDBEA2"/>
        <path d="M191.631 9.23047H191.391V65.1805H191.631V9.23047Z" fill="#CABB9F"/>
        <path d="M191.381 9.23047H191.141V65.1805H191.381V9.23047Z" fill="#C8B89C"/>
        <path d="M191.138 9.23047H190.898V65.1805H191.138V9.23047Z" fill="#C6B599"/>
        <path d="M190.888 9.23047H190.648V65.1805H190.888V9.23047Z" fill="#C3B295"/>
        <path d="M190.654 9.23047H190.414V65.1805H190.654V9.23047Z" fill="#C1AF92"/>
        <path d="M190.412 9.23047H190.172V65.1805H190.412V9.23047Z" fill="#BEAC8F"/>
        <path d="M190.162 9.23047H189.922V65.1805H190.162V9.23047Z" fill="#BCA98C"/>
        <path d="M189.92 9.23047H189.68V65.1805H189.92V9.23047Z" fill="#BAA789"/>
        <path d="M189.67 9.23047H189.43V65.1805H189.67V9.23047Z" fill="#B7A486"/>
        <path d="M189.427 9.23047H189.188V65.1805H189.427V9.23047Z" fill="#B5A183"/>
        <path d="M189.193 9.23047H188.953V65.1805H189.193V9.23047Z" fill="#B39E80"/>
        <path d="M188.943 9.23047H188.703V65.1805H188.943V9.23047Z" fill="#B09B7D"/>
        <path d="M188.701 9.23047H188.461V65.1805H188.701V9.23047Z" fill="#AE987A"/>
        <path d="M188.451 9.23047H188.211V65.1805H188.451V9.23047Z" fill="#AC9677"/>
        <path d="M188.209 9.23047H187.969V65.1805H188.209V9.23047Z" fill="#A99374"/>
        <path d="M187.967 9.23047H187.727V65.1805H187.967V9.23047Z" fill="#A79071"/>
        <path d="M187.717 9.23047H187.477V65.1805H187.717V9.23047Z" fill="#A58D6F"/>
        <path d="M187.482 9.23047H187.242V65.1805H187.482V9.23047Z" fill="#A28B6C"/>
        <path d="M187.232 9.23047H186.992V65.1805H187.232V9.23047Z" fill="#A08869"/>
        <path d="M186.99 9.23047H186.75V65.1805H186.99V9.23047Z" fill="#9E8667"/>
        <path d="M186.756 9.23047H186.516V65.1805H186.756V9.23047Z" fill="#9C8364"/>
        <path d="M186.506 9.23047H186.266V65.1805H186.506V9.23047Z" fill="#9A8062"/>
        <path d="M186.256 9.23047H186.016V65.1805H186.256V9.23047Z" fill="#987E5F"/>
        <path d="M186.006 9.23047H185.766V65.1805H186.006V9.23047Z" fill="#957C5C"/>
        <path d="M185.771 9.23047H185.531V65.1805H185.771V9.23047Z" fill="#93795A"/>
        <path d="M185.529 9.23047H185.289V65.1805H185.529V9.23047Z" fill="#917758"/>
        <path d="M185.279 9.23047H185.039V65.1805H185.279V9.23047Z" fill="#8F7455"/>
        <path d="M185.037 9.23047H184.797V65.1805H185.037V9.23047Z" fill="#8D7253"/>
        <path d="M184.787 9.23047H184.547V65.1805H184.787V9.23047Z" fill="#8B7050"/>
        <path d="M184.553 9.23047H184.312V65.1805H184.553V9.23047Z" fill="#896E4E"/>
        <path d="M184.31 9.23047H184.07V65.1805H184.31V9.23047Z" fill="#876B4B"/>
        <path d="M184.06 9.23047H183.82V65.1805H184.06V9.23047Z" fill="#856949"/>
        <path d="M183.818 9.23047H183.578V65.1805H183.818V9.23047Z" fill="#836747"/>
        <path d="M183.568 9.23047H183.328V65.1805H183.568V9.23047Z" fill="#816544"/>
        <path d="M183.334 9.23047H183.094V65.1805H183.334V9.23047Z" fill="#7F6342"/>
        <path d="M183.092 9.23047H182.852V65.1805H183.092V9.23047Z" fill="#7D6140"/>
        <path d="M182.842 9.23047H182.602V65.1805H182.842V9.23047Z" fill="#7B5F3E"/>
        <path d="M182.599 9.23047H182.359V65.1805H182.599V9.23047Z" fill="#7A5D3C"/>
        <path d="M182.349 9.23047H182.109V65.1805H182.349V9.23047Z" fill="#785B39"/>
        <path d="M182.115 9.23047H181.875V65.1805H182.115V9.23047Z" fill="#765938"/>
        <path d="M181.865 9.23047H181.625V65.1805H181.865V9.23047Z" fill="#745736"/>
        <path d="M181.615 9.23047H181.375V65.1805H181.615V9.23047Z" fill="#725534"/>
        <path d="M181.381 9.23047H181.141V65.1805H181.381V9.23047Z" fill="#715332"/>
        <path d="M181.131 9.23047H180.891V65.1805H181.131V9.23047Z" fill="#6F5130"/>
        <path d="M180.888 9.23047H180.648V65.1805H180.888V9.23047Z" fill="#6D4F2F"/>
        <path d="M180.654 9.23047H180.414V65.1805H180.654V9.23047Z" fill="#6B4D2D"/>
        <path d="M180.404 9.23047H180.164V65.1805H180.404V9.23047Z" fill="#6B4D2D"/>
        <path d="M180.162 9.23047H179.922V65.1805H180.162V9.23047Z" fill="#6C4E2D"/>
        <path d="M179.92 9.23047H179.68V65.1805H179.92V9.23047Z" fill="#6D4E2E"/>
        <path d="M179.67 9.23047H179.43V65.1805H179.67V9.23047Z" fill="#6D4F2E"/>
        <path d="M179.427 9.23047H179.188V65.1805H179.427V9.23047Z" fill="#6E502F"/>
        <path d="M179.177 9.23047H178.938V65.1805H179.177V9.23047Z" fill="#6F512F"/>
        <path d="M178.943 9.23047H178.703V65.1805H178.943V9.23047Z" fill="#70522F"/>
        <path d="M178.701 9.23047H178.461V65.1805H178.701V9.23047Z" fill="#705230"/>
        <path d="M178.451 9.23047H178.211V65.1805H178.451V9.23047Z" fill="#715330"/>
        <path d="M178.209 9.23047H177.969V65.1805H178.209V9.23047Z" fill="#725431"/>
        <path d="M177.959 9.23047H177.719V65.1805H177.959V9.23047Z" fill="#735531"/>
        <path d="M177.717 9.23047H177.477V65.1805H177.717V9.23047Z" fill="#735531"/>
        <path d="M177.482 9.23047H177.242V65.1805H177.482V9.23047Z" fill="#745632"/>
        <path d="M177.232 9.23047H176.992V65.1805H177.232V9.23047Z" fill="#755732"/>
        <path d="M176.99 9.23047H176.75V65.1805H176.99V9.23047Z" fill="#765833"/>
        <path d="M176.74 9.23047H176.5V65.1805H176.74V9.23047Z" fill="#765833"/>
        <path d="M176.506 9.23047H176.266V65.1805H176.506V9.23047Z" fill="#775934"/>
        <path d="M176.256 9.23047H176.016V65.1805H176.256V9.23047Z" fill="#785A34"/>
        <path d="M176.006 9.23047H175.766V65.1805H176.006V9.23047Z" fill="#795B34"/>
        <path d="M175.771 9.23047H175.531V65.1805H175.771V9.23047Z" fill="#795C35"/>
        <path d="M175.521 9.23047H175.281V65.1805H175.521V9.23047Z" fill="#7A5C35"/>
        <path d="M175.279 9.23047H175.039V65.1805H175.279V9.23047Z" fill="#7B5D36"/>
        <path d="M175.037 9.23047H174.797V65.1805H175.037V9.23047Z" fill="#7C5E36"/>
        <path d="M174.787 9.23047H174.547V65.1805H174.787V9.23047Z" fill="#7D5F37"/>
        <path d="M174.553 9.23047H174.312V65.1805H174.553V9.23047Z" fill="#7D6037"/>
        <path d="M174.303 9.23047H174.062V65.1805H174.303V9.23047Z" fill="#7E6038"/>
        <path d="M174.06 9.23047H173.82V65.1805H174.06V9.23047Z" fill="#7F6138"/>
        <path d="M173.818 9.23047H173.578V65.1805H173.818V9.23047Z" fill="#806239"/>
        <path d="M173.568 9.23047H173.328V65.1805H173.568V9.23047Z" fill="#816339"/>
        <path d="M173.334 9.23047H173.094V65.1805H173.334V9.23047Z" fill="#81643A"/>
        <path d="M173.084 9.23047H172.844V65.1805H173.084V9.23047Z" fill="#82653A"/>
        <path d="M172.842 9.23047H172.602V65.1805H172.842V9.23047Z" fill="#83653B"/>
        <path d="M172.599 9.23047H172.359V65.1805H172.599V9.23047Z" fill="#84663C"/>
        <path d="M172.349 9.23047H172.109V65.1805H172.349V9.23047Z" fill="#85673C"/>
        <path d="M172.115 9.23047H171.875V65.1805H172.115V9.23047Z" fill="#85683D"/>
        <path d="M171.865 9.23047H171.625V65.1805H171.865V9.23047Z" fill="#86693D"/>
        <path d="M171.615 9.23047H171.375V65.1805H171.615V9.23047Z" fill="#876A3E"/>
        <path d="M171.381 9.23047H171.141V65.1805H171.381V9.23047Z" fill="#886A3E"/>
        <path d="M171.131 9.23047H170.891V65.1805H171.131V9.23047Z" fill="#896B3F"/>
        <path d="M170.888 9.23047H170.648V65.1805H170.888V9.23047Z" fill="#896C40"/>
        <path d="M170.638 9.23047H170.398V65.1805H170.638V9.23047Z" fill="#8A6D40"/>
        <path d="M170.404 9.23047H170.164V65.1805H170.404V9.23047Z" fill="#8B6E41"/>
        <path d="M170.162 9.23047H169.922V65.1805H170.162V9.23047Z" fill="#8C6F41"/>
        <path d="M169.912 9.23047H169.672V65.1805H169.912V9.23047Z" fill="#8D7042"/>
        <path d="M169.67 9.23047H169.43V65.1805H169.67V9.23047Z" fill="#8E7143"/>
        <path d="M169.42 9.23047H169.18V65.1805H169.42V9.23047Z" fill="#8F7243"/>
        <path d="M169.177 9.23047H168.938V65.1805H169.177V9.23047Z" fill="#907244"/>
        <path d="M168.943 9.23047H168.703V65.1805H168.943V9.23047Z" fill="#907345"/>
        <path d="M168.693 9.23047H168.453V65.1805H168.693V9.23047Z" fill="#917445"/>
        <path d="M168.451 9.23047H168.211V65.1805H168.451V9.23047Z" fill="#927546"/>
        <path d="M168.201 9.23047H167.961V65.1805H168.201V9.23047Z" fill="#937647"/>
        <path d="M167.959 9.23047H167.719V65.1805H167.959V9.23047Z" fill="#947747"/>
        <path d="M167.717 9.23047H167.477V65.1805H167.717V9.23047Z" fill="#957848"/>
        <path d="M167.467 9.23047H167.227V65.1805H167.467V9.23047Z" fill="#967949"/>
        <path d="M167.232 9.23047H166.992V65.1805H167.232V9.23047Z" fill="#967A49"/>
        <path d="M166.982 9.23047H166.742V65.1805H166.982V9.23047Z" fill="#977B4A"/>
        <path d="M166.74 9.23047H166.5V65.1805H166.74V9.23047Z" fill="#987C4B"/>
        <path d="M166.506 9.23047H166.266V65.1805H166.506V9.23047Z" fill="#997D4B"/>
        <path d="M166.256 9.23047H166.016V65.1805H166.256V9.23047Z" fill="#9A7E4C"/>
        <path d="M166.006 9.23047H165.766V65.1805H166.006V9.23047Z" fill="#9B7F4D"/>
        <path d="M165.756 9.23047H165.516V65.1805H165.756V9.23047Z" fill="#9C804D"/>
        <path d="M165.521 9.23047H165.281V65.1805H165.521V9.23047Z" fill="#9D814E"/>
        <path d="M165.279 9.23047H165.039V65.1805H165.279V9.23047Z" fill="#9D824F"/>
        <path d="M165.029 9.23047H164.789V65.1805H165.029V9.23047Z" fill="#9E8350"/>
        <path d="M164.787 9.23047H164.547V65.1805H164.787V9.23047Z" fill="#9F8450"/>
        <path d="M164.537 9.23047H164.297V65.1805H164.537V9.23047Z" fill="#A08551"/>
        <path d="M164.303 9.23047H164.062V65.1805H164.303V9.23047Z" fill="#A18652"/>
        <path d="M164.06 9.23047H163.82V65.1805H164.06V9.23047Z" fill="#A28752"/>
        <path d="M163.81 9.23047H163.57V65.1805H163.81V9.23047Z" fill="#A38853"/>
        <path d="M163.568 9.23047H163.328V65.1805H163.568V9.23047Z" fill="#A48954"/>
        <path d="M163.318 9.23047H163.078V65.1805H163.318V9.23047Z" fill="#A58A55"/>
        <path d="M163.084 9.23047H162.844V65.1805H163.084V9.23047Z" fill="#A48954"/>
        <path d="M162.842 9.23047H162.602V65.1805H162.842V9.23047Z" fill="#A48853"/>
        <path d="M162.592 9.23047H162.352V65.1805H162.592V9.23047Z" fill="#A38753"/>
        <path d="M162.349 9.23047H162.109V65.1805H162.349V9.23047Z" fill="#A38652"/>
        <path d="M162.099 9.23047H161.859V65.1805H162.099V9.23047Z" fill="#A28552"/>
        <path d="M161.865 9.23047H161.625V65.1805H161.865V9.23047Z" fill="#A28451"/>
        <path d="M161.615 9.23047H161.375V65.1805H161.615V9.23047Z" fill="#A18351"/>
        <path d="M161.365 9.23047H161.125V65.1805H161.365V9.23047Z" fill="#A18250"/>
        <path d="M161.131 9.23047H160.891V65.1805H161.131V9.23047Z" fill="#A0824F"/>
        <path d="M160.881 9.23047H160.641V65.1805H160.881V9.23047Z" fill="#A0814F"/>
        <path d="M160.638 9.23047H160.398V65.1805H160.638V9.23047Z" fill="#9F804E"/>
        <path d="M160.404 9.23047H160.164V65.1805H160.404V9.23047Z" fill="#9F7F4E"/>
        <path d="M160.154 9.23047H159.914V65.1805H160.154V9.23047Z" fill="#9E7E4D"/>
        <path d="M159.912 9.23047H159.672V65.1805H159.912V9.23047Z" fill="#9E7D4D"/>
        <path d="M159.662 9.23047H159.422V65.1805H159.662V9.23047Z" fill="#9D7C4C"/>
        <path d="M159.42 9.23047H159.18V65.1805H159.42V9.23047Z" fill="#9C7B4B"/>
        <path d="M159.177 9.23047H158.938V65.1805H159.177V9.23047Z" fill="#9C7A4B"/>
        <path d="M158.927 9.23047H158.688V65.1805H158.927V9.23047Z" fill="#9B794A"/>
        <path d="M158.693 9.23047H158.453V65.1805H158.693V9.23047Z" fill="#9B794A"/>
        <path d="M158.443 9.23047H158.203V65.1805H158.443V9.23047Z" fill="#9A7849"/>
        <path d="M158.201 9.23047H157.961V65.1805H158.201V9.23047Z" fill="#9A7749"/>
        <path d="M157.959 9.23047H157.719V65.1805H157.959V9.23047Z" fill="#997648"/>
        <path d="M157.709 9.23047H157.469V65.1805H157.709V9.23047Z" fill="#997547"/>
        <path d="M157.467 9.23047H157.227V65.1805H157.467V9.23047Z" fill="#987447"/>
        <path d="M157.217 9.23047H156.977V65.1805H157.217V9.23047Z" fill="#987346"/>
        <path d="M156.982 9.23047H156.742V65.1805H156.982V9.23047Z" fill="#977246"/>
        <path d="M156.74 9.23047H156.5V65.1805H156.74V9.23047Z" fill="#977145"/>
        <path d="M156.49 9.23047H156.25V65.1805H156.49V9.23047Z" fill="#967145"/>
        <path d="M156.256 9.23047H156.016V65.1805H156.256V9.23047Z" fill="#967044"/>
        <path d="M156.006 9.23047H155.766V65.1805H156.006V9.23047Z" fill="#956F44"/>
        <path d="M155.756 9.23047H155.516V65.1805H155.756V9.23047Z" fill="#956E43"/>
        <path d="M155.521 9.23047H155.281V65.1805H155.521V9.23047Z" fill="#946D42"/>
        <path d="M155.271 9.23047H155.031V65.1805H155.271V9.23047Z" fill="#946C42"/>
        <path d="M155.029 9.23047H154.789V65.1805H155.029V9.23047Z" fill="#936B41"/>
        <path d="M154.779 9.23047H154.539V65.1805H154.779V9.23047Z" fill="#936B41"/>
        <path d="M154.537 9.23047H154.297V65.1805H154.537V9.23047Z" fill="#926A40"/>
        <path d="M154.303 9.23047H154.062V65.1805H154.303V9.23047Z" fill="#926940"/>
        <path d="M154.053 9.23047H153.812V65.1805H154.053V9.23047Z" fill="#91683F"/>
        <path d="M153.81 9.23047H153.57V65.1805H153.81V9.23047Z" fill="#91673F"/>
        <path d="M153.56 9.23047H153.32V65.1805H153.56V9.23047Z" fill="#90663E"/>
        <path d="M153.318 9.23047H153.078V65.1805H153.318V9.23047Z" fill="#8F663E"/>
        <path d="M153.084 9.23047H152.844V65.1805H153.084V9.23047Z" fill="#8F653D"/>
        <path d="M152.834 9.23047H152.594V65.1805H152.834V9.23047Z" fill="#8E643D"/>
        <path d="M152.592 9.23047H152.352V65.1805H152.592V9.23047Z" fill="#8E633C"/>
        <path d="M152.342 9.23047H152.102V65.1805H152.342V9.23047Z" fill="#8D623C"/>
        <path d="M152.099 9.23047H151.859V65.1805H152.099V9.23047Z" fill="#8D623B"/>
        <path d="M151.865 9.23047H151.625V65.1805H151.865V9.23047Z" fill="#8C613B"/>
        <path d="M151.615 9.23047H151.375V65.1805H151.615V9.23047Z" fill="#8C603A"/>
        <path d="M151.365 9.23047H151.125V65.1805H151.365V9.23047Z" fill="#8B5F39"/>
        <path d="M151.115 9.23047H150.875V65.1805H151.115V9.23047Z" fill="#8B5E39"/>
        <path d="M150.881 9.23047H150.641V65.1805H150.881V9.23047Z" fill="#8A5E38"/>
        <path d="M150.638 9.23047H150.398V65.1805H150.638V9.23047Z" fill="#8A5D38"/>
        <path d="M150.388 9.23047H150.148V65.1805H150.388V9.23047Z" fill="#895C37"/>
        <path d="M150.154 9.23047H149.914V65.1805H150.154V9.23047Z" fill="#895B37"/>
        <path d="M149.904 9.23047H149.664V65.1805H149.904V9.23047Z" fill="#885A36"/>
        <path d="M149.662 9.23047H149.422V65.1805H149.662V9.23047Z" fill="#885A36"/>
        <path d="M149.42 9.23047H149.18V65.1805H149.42V9.23047Z" fill="#875935"/>
        <path d="M149.17 9.23047H148.93V65.1805H149.17V9.23047Z" fill="#875835"/>
        <path d="M148.927 9.23047H148.688V65.1805H148.927V9.23047Z" fill="#865734"/>
        <path d="M148.677 9.23047H148.438V65.1805H148.677V9.23047Z" fill="#865734"/>
        <path d="M148.443 9.23047H148.203V65.1805H148.443V9.23047Z" fill="#855633"/>
        <path d="M148.201 9.23047H147.961V65.1805H148.201V9.23047Z" fill="#855533"/>
        <path d="M147.951 9.23047H147.711V65.1805H147.951V9.23047Z" fill="#845433"/>
        <path d="M147.709 9.23047H147.469V65.1805H147.709V9.23047Z" fill="#845432"/>
        <path d="M147.459 9.23047H147.219V65.1805H147.459V9.23047Z" fill="#835332"/>
        <path d="M147.217 9.23047H146.977V65.1805H147.217V9.23047Z" fill="#835231"/>
        <path d="M146.982 9.23047H146.742V65.1805H146.982V9.23047Z" fill="#825131"/>
        <path d="M146.732 9.23047H146.492V65.1805H146.732V9.23047Z" fill="#825130"/>
        <path d="M146.49 9.23047H146.25V65.1805H146.49V9.23047Z" fill="#815030"/>
        <path d="M146.246 9.23047H145.266V65.1805H146.246V9.23047Z" fill="#814F2F"/>
        <path d="M145.27 9.23047H143.32V65.1805H145.27V9.23047Z" fill="#814F2F"/>
      </g>
      <path
          d="M174.57 9.2307C161.15 9.2307 143.65 18.2407 143.48 18.3307L143.32 18.3807C147.68 33.7807 153.94 45.8507 174.58 65.1807C195.2 45.8607 201.47 33.7907 205.78 18.2907L205.69 18.2707L205.66 18.3207C205.48 18.2307 187.98 9.2207 174.56 9.2207L174.57 9.2307Z"
          stroke="white" stroke-width="1.02"/>
      <path d="M157.279 40.0993H191.749V18.5293H157.279V40.0993ZM192.499 40.8393H156.539V17.7793H192.499V40.8393Z"
            fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M161.529 30.4209C161.529 34.9109 162.799 37.2209 167.159 37.2209C171.209 37.2209 172.659 34.2909 172.659 30.1609V20.6309H174.099V30.8009C174.099 35.8509 171.139 38.3809 166.649 38.3809C164.129 38.3809 162.449 37.8009 161.119 36.6109C159.519 35.1709 159.319 32.8809 159.289 30.8509V20.6309H161.539V30.4209H161.529Z"
            fill="white"/>
      <path
          d="M184.83 37.3902C180.81 37.3902 177.99 34.2602 177.99 29.3602C177.99 25.0302 180.31 21.2202 185.01 21.2202C187.39 21.2202 189.17 22.6502 189.73 23.2602V21.0902C188.25 20.5602 186.66 20.2402 185.1 20.2402C179.73 20.2402 175.5 23.5202 175.5 29.2602C175.5 35.0002 178.86 38.3702 184.47 38.3702C186.26 38.3702 188.18 38.0402 189.73 37.1402V35.7002C188.84 36.3402 187.03 37.3702 184.82 37.3702"
          fill="white"/>
      <path
          d="M6.23 33.6105V32.8505H8.96V35.2405C8.54 35.5705 8.11 35.8205 7.66 35.9905C7.21 36.1605 6.76 36.2405 6.29 36.2405C5.66 36.2405 5.08 36.1106 4.57 35.8306C4.05 35.5606 3.66 35.1706 3.4 34.6506C3.14 34.1406 3 33.5705 3 32.9305C3 32.2905 3.13 31.7205 3.39 31.1705C3.65 30.6305 4.03 30.2205 4.52 29.9605C5.01 29.7005 5.58 29.5605 6.22 29.5605C6.69 29.5605 7.11 29.6406 7.48 29.7906C7.86 29.9406 8.15 30.1505 8.37 30.4205C8.59 30.6905 8.75 31.0406 8.86 31.4706L8.09 31.6805C7.99 31.3505 7.87 31.0906 7.73 30.9106C7.59 30.7306 7.38 30.5705 7.11 30.4605C6.84 30.3505 6.55 30.2906 6.22 30.2906C5.83 30.2906 5.49 30.3506 5.21 30.4706C4.93 30.5906 4.7 30.7406 4.52 30.9406C4.35 31.1306 4.21 31.3506 4.11 31.5806C3.95 31.9806 3.86 32.4105 3.86 32.8805C3.86 33.4505 3.96 33.9305 4.16 34.3205C4.36 34.7105 4.65 34.9905 5.02 35.1805C5.39 35.3705 5.8 35.4605 6.22 35.4605C6.59 35.4605 6.95 35.3905 7.3 35.2505C7.65 35.1105 7.92 34.9606 8.1 34.7906V33.5905H6.21L6.23 33.6105Z"
          fill="#231F20"/>
      <path
          d="M10.4062 36.1412L12.8763 29.7012H13.7963L16.4363 36.1412H15.4663L14.7163 34.1912H12.0262L11.3162 36.1412H10.4062ZM12.2663 33.4912H14.4462L13.7762 31.7112C13.5662 31.1712 13.4162 30.7212 13.3162 30.3712C13.2362 30.7812 13.1163 31.1912 12.9663 31.6012L12.2563 33.4912H12.2663Z"
          fill="#231F20"/>
      <path
          d="M18.0391 36.1412V29.7012H20.8991C21.4691 29.7012 21.9091 29.7612 22.2091 29.8712C22.5091 29.9812 22.7491 30.1912 22.9291 30.4812C23.1091 30.7712 23.1991 31.1012 23.1991 31.4512C23.1991 31.9112 23.0491 32.2912 22.7591 32.6112C22.4691 32.9312 22.0091 33.1212 21.3891 33.2112C21.6191 33.3212 21.7891 33.4312 21.8991 33.5312C22.1491 33.7612 22.3791 34.0412 22.6091 34.3912L23.7291 36.1412H22.6591L21.8091 34.8012C21.5591 34.4112 21.3591 34.1212 21.1891 33.9112C21.0191 33.7012 20.8791 33.5612 20.7591 33.4812C20.6291 33.4012 20.4991 33.3412 20.3691 33.3112C20.2691 33.2912 20.1191 33.2812 19.8991 33.2812H18.9091V36.1412H18.0591H18.0391ZM18.8891 32.5412H20.7191C21.1091 32.5412 21.4091 32.5012 21.6291 32.4212C21.8491 32.3412 22.0191 32.2112 22.1291 32.0312C22.2391 31.8512 22.2991 31.6612 22.2991 31.4512C22.2991 31.1512 22.1891 30.8912 21.9691 30.7012C21.7491 30.5012 21.3991 30.4112 20.9191 30.4112H18.8791V32.5412H18.8891Z"
          fill="#231F20"/>
      <path
          d="M25.4219 36.1412V29.7012H27.6419C28.1419 29.7012 28.5219 29.7312 28.7919 29.7912C29.1619 29.8712 29.4719 30.0312 29.7319 30.2512C30.0719 30.5412 30.3219 30.9012 30.4919 31.3512C30.6619 31.8012 30.7419 32.3112 30.7419 32.8812C30.7419 33.3712 30.6819 33.8012 30.5719 34.1812C30.4619 34.5612 30.3119 34.8712 30.1319 35.1212C29.9519 35.3712 29.7619 35.5612 29.5419 35.7012C29.3319 35.8412 29.0719 35.9512 28.7719 36.0212C28.4719 36.0912 28.1219 36.1312 27.7419 36.1312H25.4219V36.1412ZM26.2719 35.3812H27.6519C28.0719 35.3812 28.4119 35.3412 28.6519 35.2612C28.8919 35.1812 29.0819 35.0712 29.2319 34.9312C29.4319 34.7312 29.5919 34.4612 29.7019 34.1212C29.8119 33.7812 29.8719 33.3712 29.8719 32.8812C29.8719 32.2112 29.7619 31.6912 29.5419 31.3312C29.3219 30.9712 29.0519 30.7312 28.7319 30.6012C28.5019 30.5112 28.1319 30.4712 27.6319 30.4712H26.2819V35.3912L26.2719 35.3812Z"
          fill="#231F20"/>
      <path
          d="M32.8516 36.1412V29.7012H33.7016V32.3512H37.0516V29.7012H37.9016V36.1412H37.0516V33.1012H33.7016V36.1412H32.8516Z"
          fill="#231F20"/>
      <path d="M40.375 36.1412V29.7012H41.225V36.1412H40.375Z" fill="#231F20"/>
      <path d="M43.5881 36.1412V29.7012H44.4381V35.3812H47.6081V36.1412H43.5781H43.5881Z" fill="#231F20"/>
      <path d="M49.4944 36.1412V29.7012H50.3444V35.3812H53.5144V36.1412H49.4844H49.4944Z" fill="#231F20"/>
      <path d="M58.6406 36.1412V29.7012H59.4906V36.1412H58.6406Z" fill="#231F20"/>
      <path
          d="M65.0156 34.0698L65.8156 33.9998C65.8556 34.3198 65.9456 34.5898 66.0856 34.7898C66.2256 34.9998 66.4356 35.1598 66.7356 35.2898C67.0256 35.4198 67.3556 35.4799 67.7256 35.4799C68.0556 35.4799 68.3356 35.4298 68.5856 35.3298C68.8356 35.2298 69.0156 35.0998 69.1456 34.9298C69.2656 34.7598 69.3256 34.5798 69.3256 34.3798C69.3256 34.1798 69.2656 33.9998 69.1456 33.8498C69.0256 33.6998 68.8356 33.5698 68.5656 33.4698C68.3956 33.3998 68.0056 33.2999 67.4156 33.1599C66.8256 33.0199 66.4056 32.8798 66.1756 32.7598C65.8656 32.5998 65.6356 32.3999 65.4856 32.1599C65.3356 31.9199 65.2556 31.6498 65.2556 31.3598C65.2556 31.0298 65.3456 30.7298 65.5356 30.4498C65.7156 30.1698 65.9856 29.9498 66.3456 29.8098C66.6956 29.6598 67.0956 29.5898 67.5256 29.5898C68.0056 29.5898 68.4256 29.6698 68.7856 29.8198C69.1456 29.9698 69.4256 30.1998 69.6256 30.4998C69.8256 30.7998 69.9256 31.1398 69.9456 31.5198L69.1256 31.5798C69.0856 31.1698 68.9356 30.8699 68.6756 30.6599C68.4256 30.4499 68.0456 30.3498 67.5556 30.3498C67.0656 30.3498 66.6656 30.4398 66.4256 30.6298C66.1856 30.8198 66.0756 31.0498 66.0756 31.3098C66.0756 31.5398 66.1556 31.7298 66.3256 31.8798C66.4856 32.0298 66.9156 32.1798 67.6156 32.3398C68.3056 32.4998 68.7856 32.6298 69.0456 32.7498C69.4256 32.9198 69.6956 33.1399 69.8756 33.4099C70.0556 33.6799 70.1456 33.9798 70.1456 34.3298C70.1456 34.6798 70.0456 34.9999 69.8556 35.2999C69.6556 35.5999 69.3756 35.8398 69.0056 36.0098C68.6356 36.1798 68.2256 36.2598 67.7656 36.2598C67.1856 36.2598 66.6956 36.1798 66.3056 36.0098C65.9156 35.8398 65.6056 35.5798 65.3756 35.2398C65.1556 34.8998 65.0356 34.5098 65.0256 34.0798L65.0156 34.0698Z"
          fill="#231F20"/>
      <path
          d="M74.0391 36.1412L71.5391 29.7012H72.4591L74.1291 34.3812C74.2591 34.7612 74.3791 35.1112 74.4691 35.4312C74.5691 35.0812 74.6891 34.7312 74.8191 34.3812L76.5591 29.7012H77.4291L74.9091 36.1412H74.0291H74.0391Z"
          fill="#231F20"/>
      <path
          d="M79.125 36.1412V29.7012H83.785V30.4612H79.975V32.4312H83.535V33.1912H79.975V35.3812H83.935V36.1412H79.125Z"
          fill="#231F20"/>
      <path
          d="M86.0156 36.1412V29.7012H88.8756C89.4456 29.7012 89.8856 29.7612 90.1856 29.8712C90.4856 29.9812 90.7256 30.1912 90.9056 30.4812C91.0856 30.7712 91.1756 31.1012 91.1756 31.4512C91.1756 31.9112 91.0256 32.2912 90.7356 32.6112C90.4456 32.9312 89.9856 33.1212 89.3656 33.2112C89.5956 33.3212 89.7656 33.4312 89.8756 33.5312C90.1256 33.7612 90.3656 34.0412 90.5856 34.3912L91.7056 36.1412H90.6356L89.7856 34.8012C89.5356 34.4112 89.3256 34.1212 89.1656 33.9112C89.0056 33.7012 88.8556 33.5612 88.7356 33.4812C88.6056 33.4012 88.4756 33.3412 88.3456 33.3112C88.2456 33.2912 88.0956 33.2812 87.8756 33.2812H86.8856V36.1412H86.0356H86.0156ZM86.8656 32.5412H88.6956C89.0856 32.5412 89.3856 32.5012 89.6056 32.4212C89.8256 32.3412 89.9956 32.2112 90.1056 32.0312C90.2156 31.8512 90.2756 31.6612 90.2756 31.4512C90.2756 31.1512 90.1656 30.8912 89.9456 30.7012C89.7256 30.5012 89.3756 30.4112 88.8956 30.4112H86.8556V32.5412H86.8656Z"
          fill="#231F20"/>
      <path d="M93.5469 36.1412V29.7012H94.3969V36.1412H93.5469Z" fill="#231F20"/>
      <path
          d="M99.8238 33.6105V32.8505H102.554V35.2405C102.134 35.5705 101.704 35.8205 101.254 35.9905C100.804 36.1605 100.354 36.2405 99.8838 36.2405C99.2538 36.2405 98.6738 36.1106 98.1638 35.8306C97.6438 35.5606 97.2538 35.1706 96.9938 34.6506C96.7338 34.1406 96.5938 33.5705 96.5938 32.9305C96.5938 32.2905 96.7238 31.7205 96.9838 31.1705C97.2438 30.6305 97.6238 30.2205 98.1138 29.9605C98.6038 29.7005 99.1738 29.5605 99.8138 29.5605C100.274 29.5605 100.704 29.6406 101.074 29.7906C101.454 29.9406 101.744 30.1505 101.964 30.4205C102.184 30.6905 102.344 31.0406 102.454 31.4706L101.684 31.6805C101.584 31.3505 101.464 31.0906 101.324 30.9106C101.184 30.7306 100.974 30.5705 100.704 30.4605C100.434 30.3505 100.144 30.2906 99.8138 30.2906C99.4238 30.2906 99.0838 30.3506 98.8038 30.4706C98.5238 30.5906 98.2938 30.7406 98.1138 30.9406C97.9438 31.1306 97.8038 31.3506 97.7038 31.5806C97.5438 31.9806 97.4538 32.4105 97.4538 32.8805C97.4538 33.4505 97.5538 33.9305 97.7538 34.3205C97.9538 34.7105 98.2438 34.9905 98.6138 35.1805C98.9938 35.3705 99.3938 35.4605 99.8238 35.4605C100.194 35.4605 100.554 35.3905 100.904 35.2505C101.254 35.1105 101.524 34.9606 101.704 34.7906V33.5905H99.8138L99.8238 33.6105Z"
          fill="#231F20"/>
      <path
          d="M104.719 36.1412V29.7012H109.379V30.4612H105.569V32.4312H109.129V33.1912H105.569V35.3812H109.529V36.1412H104.719Z"
          fill="#231F20"/>
      <path
          d="M113.82 36.1392L116.29 29.6992H117.21L119.85 36.1392H118.88L118.13 34.1892H115.44L114.73 36.1392H113.82ZM115.68 33.4892H117.86L117.19 31.7092C116.98 31.1692 116.83 30.7192 116.73 30.3692C116.65 30.7792 116.53 31.1892 116.38 31.5992L115.67 33.4892H115.68Z"
          fill="#231F20"/>
      <path
          d="M121.385 36.1392V29.6992H123.805C124.295 29.6992 124.695 29.7692 124.985 29.8992C125.285 30.0292 125.515 30.2292 125.685 30.4992C125.855 30.7692 125.935 31.0492 125.935 31.3492C125.935 31.6292 125.855 31.8792 125.715 32.1292C125.565 32.3692 125.335 32.5692 125.035 32.7192C125.425 32.8292 125.725 33.0292 125.935 33.2992C126.145 33.5792 126.245 33.8992 126.245 34.2792C126.245 34.5792 126.185 34.8592 126.055 35.1192C125.925 35.3792 125.765 35.5792 125.585 35.7192C125.405 35.8592 125.165 35.9692 124.875 36.0392C124.595 36.1092 124.245 36.1492 123.835 36.1492H121.375L121.385 36.1392ZM122.235 32.4092H123.625C124.005 32.4092 124.275 32.3892 124.435 32.3392C124.655 32.2792 124.815 32.1692 124.925 32.0192C125.035 31.8692 125.095 31.6792 125.095 31.4592C125.095 31.2492 125.045 31.0592 124.945 30.8992C124.845 30.7392 124.695 30.6292 124.505 30.5692C124.315 30.5092 123.985 30.4792 123.525 30.4792H122.235V32.4292V32.4092ZM122.235 35.3892H123.835C124.115 35.3892 124.305 35.3792 124.415 35.3592C124.615 35.3192 124.775 35.2692 124.905 35.1792C125.035 35.0992 125.145 34.9792 125.235 34.8192C125.315 34.6592 125.365 34.4792 125.365 34.2792C125.365 34.0392 125.305 33.8292 125.185 33.6492C125.065 33.4692 124.895 33.3492 124.675 33.2792C124.455 33.2092 124.145 33.1692 123.735 33.1692H122.245V35.3792L122.235 35.3892Z"
          fill="#231F20"/>
      <path
          d="M0 45.6995L0.83 45.6295C0.89 46.0295 1.03 46.3395 1.26 46.5395C1.48 46.7395 1.75 46.8395 2.07 46.8395C2.45 46.8395 2.77 46.6995 3.04 46.4095C3.31 46.1195 3.44 45.7395 3.44 45.2695C3.44 44.7995 3.31 44.4595 3.06 44.1995C2.81 43.9395 2.47 43.8095 2.06 43.8095C1.81 43.8095 1.58 43.8695 1.37 43.9795C1.16 44.0995 1 44.2495 0.89 44.4295L0.15 44.3295L0.77 41.0195H3.97V41.7795H1.4L1.05 43.5095C1.44 43.2395 1.84 43.1095 2.27 43.1095C2.83 43.1095 3.31 43.3095 3.69 43.6895C4.08 44.0795 4.27 44.5795 4.27 45.1895C4.27 45.7995 4.1 46.2795 3.76 46.6995C3.35 47.2195 2.78 47.4795 2.07 47.4795C1.48 47.4795 1.01 47.3195 0.64 46.9895C0.27 46.6595 0.06 46.2295 0 45.6895L0 45.6995Z"
          fill="#231F20"/>
      <path
          d="M5.90625 45.6995L6.73625 45.6295C6.79625 46.0295 6.93625 46.3395 7.16625 46.5395C7.38625 46.7395 7.65625 46.8395 7.97625 46.8395C8.35625 46.8395 8.67625 46.6995 8.94625 46.4095C9.21625 46.1195 9.34625 45.7395 9.34625 45.2695C9.34625 44.7995 9.21625 44.4595 8.96625 44.1995C8.71625 43.9395 8.37625 43.8095 7.96625 43.8095C7.71625 43.8095 7.48625 43.8695 7.27625 43.9795C7.06625 44.0995 6.90625 44.2495 6.79625 44.4295L6.05625 44.3295L6.67625 41.0195H9.87625V41.7795H7.30625L6.95625 43.5095C7.34625 43.2395 7.74625 43.1095 8.17625 43.1095C8.73625 43.1095 9.21625 43.3095 9.59625 43.6895C9.98625 44.0795 10.1763 44.5795 10.1763 45.1895C10.1763 45.7995 10.0063 46.2795 9.66625 46.6995C9.25625 47.2195 8.68625 47.4795 7.97625 47.4795C7.38625 47.4795 6.91625 47.3195 6.54625 46.9895C6.17625 46.6595 5.96625 46.2295 5.90625 45.6895V45.6995Z"
          fill="#231F20"/>
      <path
          d="M15.9169 42.5206L15.1269 42.5806C15.0569 42.2706 14.9569 42.0406 14.8269 41.9006C14.6169 41.6806 14.3469 41.5606 14.0369 41.5606C13.7869 41.5606 13.5669 41.6306 13.3769 41.7706C13.1269 41.9506 12.9269 42.2206 12.7869 42.5606C12.6469 42.9106 12.5669 43.4106 12.5669 44.0506C12.7569 43.7606 12.9869 43.5406 13.2669 43.4006C13.5469 43.2606 13.8269 43.1906 14.1369 43.1906C14.6669 43.1906 15.1169 43.3806 15.4869 43.7706C15.8569 44.1606 16.0469 44.6606 16.0469 45.2706C16.0469 45.6706 15.9569 46.0506 15.7869 46.4006C15.6169 46.7506 15.3769 47.0106 15.0669 47.2006C14.7569 47.3906 14.4169 47.4806 14.0269 47.4806C13.3669 47.4806 12.8269 47.2406 12.4169 46.7506C11.9969 46.2606 11.7969 45.4706 11.7969 44.3506C11.7969 43.1006 12.0269 42.2006 12.4869 41.6306C12.8869 41.1406 13.4269 40.8906 14.1069 40.8906C14.6169 40.8906 15.0269 41.0306 15.3569 41.3206C15.6769 41.6106 15.8769 42.0006 15.9369 42.5006L15.9169 42.5206ZM12.6869 45.3006C12.6869 45.5706 12.7469 45.8306 12.8569 46.0806C12.9769 46.3306 13.1369 46.5206 13.3469 46.6506C13.5569 46.7806 13.7769 46.8506 13.9969 46.8506C14.3269 46.8506 14.6169 46.7206 14.8569 46.4506C15.0969 46.1806 15.2169 45.8206 15.2169 45.3506C15.2169 44.8806 15.0969 44.5506 14.8569 44.3006C14.6169 44.0406 14.3169 43.9206 13.9569 43.9206C13.5969 43.9206 13.2969 44.0506 13.0469 44.3006C12.7969 44.5606 12.6769 44.8906 12.6769 45.3106L12.6869 45.3006Z"
          fill="#231F20"/>
      <path
          d="M18.9309 43.8997C18.6009 43.7797 18.3609 43.6097 18.2009 43.3897C18.0409 43.1697 17.9609 42.8997 17.9609 42.5897C17.9609 42.1197 18.1309 41.7297 18.4709 41.4097C18.8109 41.0897 19.2609 40.9297 19.8109 40.9297C20.3609 40.9297 20.8309 41.0897 21.1709 41.4197C21.5109 41.7497 21.6809 42.1397 21.6809 42.6097C21.6809 42.9097 21.6009 43.1697 21.4509 43.3897C21.2909 43.6097 21.0509 43.7797 20.7409 43.8997C21.1409 44.0297 21.4409 44.2397 21.6409 44.5197C21.8509 44.8097 21.9509 45.1497 21.9509 45.5497C21.9509 46.0997 21.7609 46.5597 21.3709 46.9397C20.9809 47.3197 20.4709 47.4997 19.8309 47.4997C19.1909 47.4997 18.6809 47.3097 18.2909 46.9397C17.9009 46.5597 17.7109 46.0897 17.7109 45.5297C17.7109 45.1097 17.8209 44.7597 18.0309 44.4797C18.2409 44.1997 18.5409 44.0097 18.9409 43.8997H18.9309ZM18.5209 45.5297C18.5209 45.7597 18.5709 45.9697 18.6809 46.1897C18.7909 46.4097 18.9509 46.5597 19.1609 46.6797C19.3709 46.7997 19.6009 46.8497 19.8409 46.8497C20.2209 46.8497 20.5309 46.7297 20.7809 46.4897C21.0309 46.2497 21.1509 45.9397 21.1509 45.5597C21.1509 45.1797 21.0209 44.8597 20.7709 44.6097C20.5209 44.3597 20.2009 44.2397 19.8209 44.2397C19.4409 44.2397 19.1409 44.3597 18.9009 44.6097C18.6509 44.8597 18.5309 45.1597 18.5309 45.5297H18.5209ZM18.7709 42.5597C18.7709 42.8597 18.8709 43.1097 19.0609 43.3097C19.2509 43.5097 19.5109 43.5997 19.8209 43.5997C20.1309 43.5997 20.3709 43.4997 20.5709 43.3097C20.7609 43.1197 20.8609 42.8797 20.8609 42.5997C20.8609 42.3197 20.7609 42.0697 20.5609 41.8697C20.3609 41.6697 20.1109 41.5697 19.8109 41.5697C19.5109 41.5697 19.2609 41.6697 19.0609 41.8597C18.8609 42.0497 18.7609 42.2797 18.7609 42.5497L18.7709 42.5597Z"
          fill="#231F20"/>
      <path
          d="M27.7294 42.5206L26.9394 42.5806C26.8694 42.2706 26.7694 42.0406 26.6394 41.9006C26.4294 41.6806 26.1594 41.5606 25.8494 41.5606C25.5994 41.5606 25.3794 41.6306 25.1894 41.7706C24.9394 41.9506 24.7394 42.2206 24.5994 42.5606C24.4594 42.9106 24.3794 43.4106 24.3794 44.0506C24.5694 43.7606 24.7994 43.5406 25.0794 43.4006C25.3594 43.2606 25.6394 43.1906 25.9494 43.1906C26.4794 43.1906 26.9294 43.3806 27.2994 43.7706C27.6694 44.1606 27.8594 44.6606 27.8594 45.2706C27.8594 45.6706 27.7694 46.0506 27.5994 46.4006C27.4294 46.7506 27.1894 47.0106 26.8794 47.2006C26.5694 47.3906 26.2294 47.4806 25.8394 47.4806C25.1794 47.4806 24.6394 47.2406 24.2294 46.7506C23.8094 46.2606 23.6094 45.4706 23.6094 44.3506C23.6094 43.1006 23.8394 42.2006 24.2994 41.6306C24.6994 41.1406 25.2394 40.8906 25.9194 40.8906C26.4294 40.8906 26.8394 41.0306 27.1694 41.3206C27.4894 41.6106 27.6894 42.0006 27.7494 42.5006L27.7294 42.5206ZM24.4994 45.3006C24.4994 45.5706 24.5594 45.8306 24.6694 46.0806C24.7894 46.3306 24.9494 46.5206 25.1594 46.6506C25.3694 46.7806 25.5894 46.8506 25.8094 46.8506C26.1394 46.8506 26.4294 46.7206 26.6694 46.4506C26.9094 46.1806 27.0294 45.8206 27.0294 45.3506C27.0294 44.8806 26.9094 44.5506 26.6694 44.3006C26.4294 44.0406 26.1294 43.9206 25.7694 43.9206C25.4094 43.9206 25.1094 44.0506 24.8594 44.3006C24.6094 44.5606 24.4894 44.8906 24.4894 45.3106L24.4994 45.3006Z"
          fill="#231F20"/>
      <path
          d="M29.5312 45.6995L30.3612 45.6295C30.4212 46.0295 30.5613 46.3395 30.7913 46.5395C31.0113 46.7395 31.2812 46.8395 31.6012 46.8395C31.9812 46.8395 32.3012 46.6995 32.5712 46.4095C32.8412 46.1195 32.9713 45.7395 32.9713 45.2695C32.9713 44.7995 32.8412 44.4595 32.5912 44.1995C32.3412 43.9395 32.0012 43.8095 31.5912 43.8095C31.3412 43.8095 31.1112 43.8695 30.9012 43.9795C30.6912 44.0995 30.5312 44.2495 30.4212 44.4295L29.6812 44.3295L30.3012 41.0195H33.5012V41.7795H30.9312L30.5812 43.5095C30.9712 43.2395 31.3712 43.1095 31.8012 43.1095C32.3612 43.1095 32.8413 43.3095 33.2213 43.6895C33.6113 44.0795 33.8012 44.5795 33.8012 45.1895C33.8012 45.7995 33.6313 46.2795 33.2913 46.6995C32.8813 47.2195 32.3112 47.4795 31.6012 47.4795C31.0112 47.4795 30.5412 47.3195 30.1712 46.9895C29.8012 46.6595 29.5912 46.2295 29.5312 45.6895V45.6995Z"
          fill="#231F20"/>
      <path d="M35.3438 45.4599V44.6699H37.7738V45.4599H35.3438Z" fill="#231F20"/>
      <path
          d="M43.4325 42.5206L42.6425 42.5806C42.5725 42.2706 42.4725 42.0406 42.3425 41.9006C42.1325 41.6806 41.8625 41.5606 41.5525 41.5606C41.3025 41.5606 41.0825 41.6306 40.8925 41.7706C40.6425 41.9506 40.4425 42.2206 40.3025 42.5606C40.1625 42.9106 40.0825 43.4106 40.0825 44.0506C40.2725 43.7606 40.5025 43.5406 40.7825 43.4006C41.0625 43.2606 41.3425 43.1906 41.6525 43.1906C42.1825 43.1906 42.6325 43.3806 43.0025 43.7706C43.3725 44.1606 43.5625 44.6606 43.5625 45.2706C43.5625 45.6706 43.4725 46.0506 43.3025 46.4006C43.1325 46.7506 42.8925 47.0106 42.5825 47.2006C42.2725 47.3906 41.9325 47.4806 41.5425 47.4806C40.8825 47.4806 40.3425 47.2406 39.9325 46.7506C39.5125 46.2606 39.3125 45.4706 39.3125 44.3506C39.3125 43.1006 39.5425 42.2006 40.0025 41.6306C40.4025 41.1406 40.9425 40.8906 41.6225 40.8906C42.1325 40.8906 42.5425 41.0306 42.8725 41.3206C43.1925 41.6106 43.3925 42.0006 43.4525 42.5006L43.4325 42.5206ZM40.2025 45.3006C40.2025 45.5706 40.2625 45.8306 40.3725 46.0806C40.4925 46.3306 40.6525 46.5206 40.8625 46.6506C41.0725 46.7806 41.2925 46.8506 41.5125 46.8506C41.8425 46.8506 42.1325 46.7206 42.3725 46.4506C42.6125 46.1806 42.7325 45.8206 42.7325 45.3506C42.7325 44.8806 42.6125 44.5506 42.3725 44.3006C42.1325 44.0406 41.8325 43.9206 41.4725 43.9206C41.1125 43.9206 40.8125 44.0506 40.5625 44.3006C40.3125 44.5606 40.1925 44.8906 40.1925 45.3106L40.2025 45.3006Z"
          fill="#231F20"/>
      <path
          d="M45.3444 45.9002L46.1044 45.8302C46.1644 46.1902 46.2944 46.4502 46.4744 46.6102C46.6544 46.7702 46.8844 46.8502 47.1744 46.8502C47.4144 46.8502 47.6344 46.8002 47.8144 46.6802C47.9944 46.5702 48.1444 46.4202 48.2644 46.2302C48.3844 46.0402 48.4844 45.7902 48.5544 45.4802C48.6244 45.1702 48.6744 44.8402 48.6744 44.5102C48.6744 44.4702 48.6744 44.4202 48.6744 44.3502C48.5144 44.6002 48.3044 44.8102 48.0244 44.9602C47.7544 45.1202 47.4544 45.2001 47.1344 45.2001C46.6044 45.2001 46.1544 45.0102 45.7844 44.6202C45.4144 44.2302 45.2344 43.7201 45.2344 43.0901C45.2344 42.4601 45.4244 41.9102 45.8144 41.5102C46.2044 41.1102 46.6844 40.9102 47.2644 40.9102C47.6844 40.9102 48.0644 41.0202 48.4144 41.2502C48.7644 41.4802 49.0244 41.8002 49.2044 42.2102C49.3844 42.6302 49.4744 43.2301 49.4744 44.0201C49.4744 44.8101 49.3844 45.5002 49.2044 45.9902C49.0244 46.4802 48.7644 46.8502 48.4044 47.1002C48.0444 47.3502 47.6344 47.4802 47.1644 47.4802C46.6644 47.4802 46.2444 47.3402 45.9344 47.0602C45.6144 46.7801 45.4244 46.3902 45.3544 45.8801L45.3444 45.9002ZM48.5844 43.0602C48.5844 42.6102 48.4644 42.2502 48.2244 41.9802C47.9844 41.7102 47.6944 41.5802 47.3544 41.5802C47.0144 41.5802 46.6944 41.7202 46.4344 42.0102C46.1744 42.3002 46.0444 42.6701 46.0444 43.1301C46.0444 43.5401 46.1644 43.8701 46.4144 44.1301C46.6644 44.3901 46.9644 44.5102 47.3344 44.5102C47.7044 44.5102 48.0044 44.3801 48.2344 44.1301C48.4744 43.8701 48.5844 43.5202 48.5844 43.0602Z"
          fill="#231F20"/>
      <path
          d="M52.3606 43.8997C52.0306 43.7797 51.7906 43.6097 51.6306 43.3897C51.4706 43.1697 51.3906 42.8997 51.3906 42.5897C51.3906 42.1197 51.5606 41.7297 51.9006 41.4097C52.2406 41.0897 52.6906 40.9297 53.2406 40.9297C53.7906 40.9297 54.2606 41.0897 54.6006 41.4197C54.9406 41.7497 55.1106 42.1397 55.1106 42.6097C55.1106 42.9097 55.0306 43.1697 54.8806 43.3897C54.7206 43.6097 54.4806 43.7797 54.1706 43.8997C54.5706 44.0297 54.8706 44.2397 55.0706 44.5197C55.2806 44.8097 55.3806 45.1497 55.3806 45.5497C55.3806 46.0997 55.1906 46.5597 54.8006 46.9397C54.4106 47.3197 53.9006 47.4997 53.2606 47.4997C52.6206 47.4997 52.1106 47.3097 51.7206 46.9397C51.3306 46.5597 51.1406 46.0897 51.1406 45.5297C51.1406 45.1097 51.2506 44.7597 51.4606 44.4797C51.6706 44.1997 51.9706 44.0097 52.3706 43.8997H52.3606ZM51.9506 45.5297C51.9506 45.7597 52.0006 45.9697 52.1106 46.1897C52.2206 46.4097 52.3806 46.5597 52.5906 46.6797C52.8006 46.7997 53.0306 46.8497 53.2706 46.8497C53.6506 46.8497 53.9606 46.7297 54.2106 46.4897C54.4606 46.2497 54.5806 45.9397 54.5806 45.5597C54.5806 45.1797 54.4506 44.8597 54.2006 44.6097C53.9506 44.3597 53.6306 44.2397 53.2506 44.2397C52.8706 44.2397 52.5706 44.3597 52.3306 44.6097C52.0806 44.8597 51.9606 45.1597 51.9606 45.5297H51.9506ZM52.2006 42.5597C52.2006 42.8597 52.3006 43.1097 52.4906 43.3097C52.6806 43.5097 52.9406 43.5997 53.2506 43.5997C53.5606 43.5997 53.8006 43.4997 54.0006 43.3097C54.1906 43.1197 54.2906 42.8797 54.2906 42.5997C54.2906 42.3197 54.1906 42.0697 53.9906 41.8697C53.7906 41.6697 53.5406 41.5697 53.2406 41.5697C52.9406 41.5697 52.6906 41.6697 52.4906 41.8597C52.2906 42.0497 52.1906 42.2797 52.1906 42.5497L52.2006 42.5597Z"
          fill="#231F20"/>
      <path
          d="M61.1513 42.5206L60.3613 42.5806C60.2913 42.2706 60.1913 42.0406 60.0613 41.9006C59.8513 41.6806 59.5813 41.5606 59.2713 41.5606C59.0213 41.5606 58.8013 41.6306 58.6113 41.7706C58.3613 41.9506 58.1613 42.2206 58.0213 42.5606C57.8813 42.9106 57.8013 43.4106 57.8013 44.0506C57.9913 43.7606 58.2213 43.5406 58.5013 43.4006C58.7713 43.2606 59.0613 43.1906 59.3713 43.1906C59.9013 43.1906 60.3513 43.3806 60.7213 43.7706C61.0913 44.1606 61.2812 44.6606 61.2812 45.2706C61.2812 45.6706 61.1913 46.0506 61.0213 46.4006C60.8513 46.7506 60.6113 47.0106 60.3013 47.2006C59.9913 47.3906 59.6512 47.4806 59.2612 47.4806C58.6012 47.4806 58.0613 47.2406 57.6513 46.7506C57.2313 46.2606 57.0312 45.4706 57.0312 44.3506C57.0312 43.1006 57.2613 42.2006 57.7213 41.6306C58.1213 41.1406 58.6613 40.8906 59.3413 40.8906C59.8513 40.8906 60.2613 41.0306 60.5913 41.3206C60.9113 41.6106 61.1112 42.0006 61.1712 42.5006L61.1513 42.5206ZM57.9212 45.3006C57.9212 45.5706 57.9813 45.8306 58.0913 46.0806C58.2113 46.3306 58.3713 46.5206 58.5813 46.6506C58.7913 46.7806 59.0113 46.8506 59.2413 46.8506C59.5713 46.8506 59.8612 46.7206 60.1012 46.4506C60.3412 46.1806 60.4613 45.8206 60.4613 45.3506C60.4613 44.8806 60.3412 44.5506 60.1012 44.3006C59.8612 44.0406 59.5613 43.9206 59.2013 43.9206C58.8413 43.9206 58.5413 44.0506 58.2913 44.3006C58.0413 44.5606 57.9212 44.8906 57.9212 45.3106V45.3006Z"
          fill="#231F20"/>
      <path d="M66.7969 49.2801V40.8301H67.4869V49.2801H66.7969Z" fill="#231F20"/>
      <path
          d="M77.1506 46.6299V47.3899H72.8906C72.8906 47.1999 72.9106 47.0199 72.9806 46.8399C73.0906 46.5499 73.2606 46.2599 73.5006 45.9799C73.7406 45.6999 74.0806 45.3699 74.5306 44.9999C75.2306 44.4299 75.7006 43.9699 75.9506 43.6399C76.2006 43.2999 76.3206 42.9899 76.3206 42.6899C76.3206 42.3799 76.2106 42.1099 75.9806 41.8999C75.7606 41.6899 75.4606 41.5799 75.1006 41.5799C74.7206 41.5799 74.4106 41.6999 74.1906 41.9199C73.9706 42.1399 73.8506 42.4599 73.8406 42.8699L73.0306 42.7899C73.0906 42.1799 73.2906 41.7199 73.6606 41.3999C74.0206 41.0799 74.5106 40.9199 75.1206 40.9199C75.7306 40.9199 76.2306 41.0899 76.5906 41.4299C76.9506 41.7699 77.1306 42.1999 77.1306 42.7099C77.1306 42.9699 77.0806 43.2199 76.9706 43.4699C76.8606 43.7199 76.6906 43.9799 76.4506 44.2599C76.2006 44.5399 75.8006 44.9099 75.2306 45.3899C74.7606 45.7899 74.4506 46.0599 74.3206 46.1999C74.1906 46.3399 74.0706 46.4899 73.9906 46.6299H77.1506Z"
          fill="#231F20"/>
      <path
          d="M78.8906 44.2099C78.8906 43.4499 78.9706 42.8399 79.1306 42.3699C79.2906 41.9099 79.5206 41.5499 79.8306 41.2999C80.1406 41.0499 80.5306 40.9199 81.0006 40.9199C81.3406 40.9199 81.6506 40.9899 81.9106 41.1299C82.1706 41.2699 82.3906 41.4699 82.5606 41.7299C82.7306 41.9899 82.8606 42.3099 82.9606 42.6899C83.0606 43.0699 83.1006 43.5699 83.1006 44.2099C83.1006 44.9699 83.0206 45.5799 82.8706 46.0399C82.7106 46.4999 82.4806 46.8599 82.1706 47.1199C81.8606 47.3699 81.4706 47.4999 81.0006 47.4999C80.3806 47.4999 79.8906 47.2799 79.5406 46.8299C79.1206 46.2899 78.9006 45.4199 78.9006 44.2099H78.8906ZM79.7006 44.2099C79.7006 45.2699 79.8206 45.9699 80.0706 46.3199C80.3206 46.6699 80.6206 46.8399 80.9906 46.8399C81.3606 46.8399 81.6606 46.6599 81.9106 46.3099C82.1606 45.9599 82.2806 45.2599 82.2806 44.1999C82.2806 43.1399 82.1606 42.4399 81.9106 42.0899C81.6606 41.7399 81.3506 41.5699 80.9806 41.5699C80.6106 41.5699 80.3306 41.7199 80.1106 42.0299C79.8406 42.4199 79.7006 43.1499 79.7006 44.1999V44.2099Z"
          fill="#231F20"/>
      <path
          d="M88.9631 46.6299V47.3899H84.7031C84.7031 47.1999 84.7231 47.0199 84.7931 46.8399C84.9031 46.5499 85.0731 46.2599 85.3131 45.9799C85.5531 45.6999 85.8931 45.3699 86.3431 44.9999C87.0431 44.4299 87.5131 43.9699 87.7631 43.6399C88.0131 43.2999 88.1331 42.9899 88.1331 42.6899C88.1331 42.3799 88.0231 42.1099 87.7931 41.8999C87.5731 41.6899 87.2731 41.5799 86.9131 41.5799C86.5331 41.5799 86.2231 41.6999 86.0031 41.9199C85.7831 42.1399 85.6631 42.4599 85.6531 42.8699L84.8431 42.7899C84.9031 42.1799 85.1031 41.7199 85.4731 41.3999C85.8331 41.0799 86.3231 40.9199 86.9331 40.9199C87.5431 40.9199 88.0431 41.0899 88.4031 41.4299C88.7631 41.7699 88.9431 42.1999 88.9431 42.7099C88.9431 42.9699 88.8931 43.2199 88.7831 43.4699C88.6731 43.7199 88.5031 43.9799 88.2631 44.2599C88.0131 44.5399 87.6131 44.9099 87.0431 45.3899C86.5731 45.7899 86.2631 46.0599 86.1331 46.1999C86.0031 46.3399 85.8831 46.4899 85.8031 46.6299H88.9631Z"
          fill="#231F20"/>
      <path
          d="M90.7131 45.6899L91.5031 45.5799C91.5931 46.0299 91.7531 46.3499 91.9631 46.5499C92.1831 46.7499 92.4431 46.8499 92.7631 46.8499C93.1331 46.8499 93.4531 46.7199 93.7031 46.4599C93.9631 46.1999 94.0831 45.8799 94.0831 45.4999C94.0831 45.1199 93.9631 44.8399 93.7231 44.5999C93.4831 44.3599 93.1831 44.2499 92.8131 44.2499C92.6631 44.2499 92.4731 44.2799 92.2531 44.3399L92.3431 43.6499C92.3931 43.6499 92.4431 43.6499 92.4731 43.6499C92.8131 43.6499 93.1131 43.5599 93.3831 43.3899C93.6531 43.2199 93.7831 42.9399 93.7831 42.5799C93.7831 42.2899 93.6831 42.0499 93.4931 41.8599C93.2931 41.6699 93.0431 41.5699 92.7331 41.5699C92.4231 41.5699 92.1731 41.6699 91.9631 41.8599C91.7531 42.0499 91.6231 42.3399 91.5631 42.7299L90.7731 42.5899C90.8731 42.0599 91.0931 41.6499 91.4331 41.3599C91.7731 41.0699 92.2031 40.9199 92.7131 40.9199C93.0631 40.9199 93.3931 40.9999 93.6831 41.1499C93.9831 41.2999 94.2031 41.5099 94.3631 41.7699C94.5231 42.0299 94.6031 42.3099 94.6031 42.5999C94.6031 42.8899 94.5231 43.1299 94.3831 43.3599C94.2331 43.5899 94.0131 43.7699 93.7231 43.8999C94.1031 43.9899 94.4031 44.1699 94.6131 44.4499C94.8231 44.7299 94.9331 45.0699 94.9331 45.4899C94.9331 46.0499 94.7231 46.5299 94.3131 46.9199C93.9031 47.3099 93.3831 47.5099 92.7531 47.5099C92.1831 47.5099 91.7131 47.3399 91.3431 47.0099C90.9731 46.6699 90.7531 46.2399 90.7031 45.6999L90.7131 45.6899Z"
          fill="#231F20"/>
      <path d="M96.5156 45.4599V44.6699H98.9456V45.4599H96.5156Z" fill="#231F20"/>
      <path
          d="M100.516 44.2099C100.516 43.4499 100.596 42.8399 100.756 42.3699C100.916 41.9099 101.146 41.5499 101.456 41.2999C101.766 41.0499 102.156 40.9199 102.626 40.9199C102.966 40.9199 103.276 40.9899 103.536 41.1299C103.796 41.2699 104.016 41.4699 104.186 41.7299C104.356 41.9899 104.486 42.3099 104.586 42.6899C104.686 43.0699 104.726 43.5699 104.726 44.2099C104.726 44.9699 104.646 45.5799 104.496 46.0399C104.336 46.4999 104.106 46.8599 103.796 47.1199C103.486 47.3699 103.096 47.4999 102.626 47.4999C102.006 47.4999 101.516 47.2799 101.166 46.8299C100.746 46.2899 100.526 45.4199 100.526 44.2099H100.516ZM101.326 44.2099C101.326 45.2699 101.446 45.9699 101.696 46.3199C101.946 46.6699 102.246 46.8399 102.616 46.8399C102.986 46.8399 103.286 46.6599 103.536 46.3099C103.786 45.9599 103.906 45.2599 103.906 44.1999C103.906 43.1399 103.786 42.4399 103.536 42.0899C103.286 41.7399 102.976 41.5699 102.606 41.5699C102.236 41.5699 101.956 41.7199 101.736 42.0299C101.466 42.4199 101.326 43.1499 101.326 44.1999V44.2099Z"
          fill="#231F20"/>
      <path
          d="M106.406 45.6995L107.236 45.6295C107.296 46.0295 107.436 46.3395 107.666 46.5395C107.886 46.7395 108.156 46.8395 108.476 46.8395C108.856 46.8395 109.176 46.6995 109.446 46.4095C109.716 46.1195 109.846 45.7395 109.846 45.2695C109.846 44.7995 109.716 44.4595 109.466 44.1995C109.216 43.9395 108.876 43.8095 108.466 43.8095C108.216 43.8095 107.986 43.8695 107.776 43.9795C107.566 44.0995 107.406 44.2495 107.296 44.4295L106.556 44.3295L107.176 41.0195H110.376V41.7795H107.806L107.456 43.5095C107.846 43.2395 108.246 43.1095 108.676 43.1095C109.236 43.1095 109.716 43.3095 110.096 43.6895C110.486 44.0795 110.676 44.5795 110.676 45.1895C110.676 45.7995 110.506 46.2795 110.166 46.6995C109.756 47.2195 109.186 47.4795 108.476 47.4795C107.886 47.4795 107.416 47.3195 107.046 46.9895C106.676 46.6595 106.466 46.2295 106.406 45.6895V45.6995Z"
          fill="#231F20"/>
      <path d="M112.234 45.4599V44.6699H114.664V45.4599H112.234Z" fill="#231F20"/>
      <path
          d="M119.198 47.3899H118.408V42.3499C118.218 42.5299 117.968 42.7099 117.658 42.8899C117.348 43.0699 117.068 43.2099 116.828 43.2999V42.5399C117.268 42.3299 117.658 42.0799 117.988 41.7799C118.318 41.4799 118.548 41.1999 118.688 40.9199H119.198V47.3899Z"
          fill="#231F20"/>
      <path
          d="M126.229 42.5206L125.439 42.5806C125.369 42.2706 125.269 42.0406 125.139 41.9006C124.929 41.6806 124.659 41.5606 124.349 41.5606C124.099 41.5606 123.879 41.6306 123.689 41.7706C123.439 41.9506 123.239 42.2206 123.099 42.5606C122.959 42.9106 122.879 43.4106 122.879 44.0506C123.069 43.7606 123.299 43.5406 123.579 43.4006C123.849 43.2606 124.139 43.1906 124.449 43.1906C124.979 43.1906 125.429 43.3806 125.799 43.7706C126.169 44.1606 126.359 44.6606 126.359 45.2706C126.359 45.6706 126.269 46.0506 126.099 46.4006C125.929 46.7506 125.689 47.0106 125.379 47.2006C125.069 47.3906 124.729 47.4806 124.339 47.4806C123.679 47.4806 123.139 47.2406 122.729 46.7506C122.309 46.2606 122.109 45.4706 122.109 44.3506C122.109 43.1006 122.339 42.2006 122.799 41.6306C123.199 41.1406 123.739 40.8906 124.419 40.8906C124.929 40.8906 125.339 41.0306 125.669 41.3206C125.989 41.6106 126.189 42.0006 126.249 42.5006L126.229 42.5206ZM122.999 45.3006C122.999 45.5706 123.059 45.8306 123.169 46.0806C123.289 46.3306 123.449 46.5206 123.659 46.6506C123.869 46.7806 124.089 46.8506 124.319 46.8506C124.649 46.8506 124.939 46.7206 125.179 46.4506C125.419 46.1806 125.539 45.8206 125.539 45.3506C125.539 44.8806 125.419 44.5506 125.179 44.3006C124.939 44.0406 124.639 43.9206 124.279 43.9206C123.919 43.9206 123.619 44.0506 123.369 44.3006C123.119 44.5606 122.999 44.8906 122.999 45.3106V45.3006Z"
          fill="#231F20"/>
      <path
          d="M31.2188 17.3203H32.2188V21.0603H32.2488C32.4588 20.7603 32.6588 20.4803 32.8588 20.2303L35.2288 17.3203H36.4688L33.6588 20.6103L36.6887 25.0703H35.5087L32.9587 21.2603L32.2188 22.1103V25.0703H31.2188V17.3203Z"
          fill="#231F20"/>
      <path
          d="M38.1094 17.4315C38.6194 17.3315 39.3394 17.2715 40.0294 17.2715C41.0994 17.2715 41.7894 17.4715 42.2694 17.9015C42.6594 18.2515 42.8794 18.7715 42.8794 19.3715C42.8794 20.3915 42.2394 21.0715 41.4194 21.3515V21.3815C42.0194 21.5915 42.3694 22.1415 42.5594 22.9415C42.8094 24.0215 42.9994 24.7715 43.1594 25.0715H42.1194C41.9894 24.8515 41.8194 24.1815 41.5994 23.2215C41.3694 22.1515 40.9594 21.7515 40.0494 21.7115H39.1094V25.0715H38.1094V17.4215V17.4315ZM39.1094 20.9615H40.1294C41.1994 20.9615 41.8794 20.3715 41.8794 19.4915C41.8794 18.4915 41.1594 18.0515 40.0994 18.0415C39.6194 18.0415 39.2694 18.0915 39.1094 18.1315V20.9615Z"
          fill="#231F20"/>
      <path
          d="M48.9631 21.4401H45.9531V24.2401H49.3131V25.0801H44.9531V17.3301H49.1431V18.1701H45.9531V20.6201H48.9631V21.4501V21.4401Z"
          fill="#231F20"/>
      <path
          d="M51.26 17.4315C51.87 17.3415 52.59 17.2715 53.39 17.2715C54.83 17.2715 55.85 17.6015 56.53 18.2415C57.22 18.8715 57.62 19.7715 57.62 21.0215C57.62 22.2715 57.23 23.3215 56.5 24.0315C55.78 24.7515 54.58 25.1515 53.07 25.1515C52.36 25.1515 51.76 25.1215 51.25 25.0615V17.4315H51.26ZM52.26 24.2915C52.51 24.3415 52.88 24.3515 53.27 24.3515C55.41 24.3515 56.57 23.1515 56.57 21.0615C56.58 19.2315 55.55 18.0715 53.43 18.0715C52.91 18.0715 52.52 18.1215 52.26 18.1715V24.2915Z"
          fill="#231F20"/>
      <path d="M60.5781 17.3203V25.0703H59.5781V17.3203H60.5781Z" fill="#231F20"/>
      <path d="M64.4537 18.1703H62.0938V17.3203H67.8338V18.1703H65.4637V25.0703H64.4537V18.1703Z" fill="#231F20"/>
      <path
          d="M71.0612 25.0801L68.5312 17.3301H69.6113L70.8213 21.1501C71.1513 22.2001 71.4413 23.1401 71.6513 24.0501H71.6712C71.8912 23.1501 72.2112 22.1701 72.5612 21.1601L73.8713 17.3301H74.9413L72.1712 25.0801H71.0713H71.0612Z"
          fill="#231F20"/>
      <path
          d="M77.6781 22.6406L76.8681 25.0806H75.8281L78.4581 17.3306H79.6681L82.3181 25.0806H81.2481L80.4181 22.6406H77.6681H77.6781ZM77.4781 16.2206C77.4781 15.9006 77.7181 15.6406 78.0581 15.6406C78.3681 15.6406 78.5981 15.8906 78.5981 16.2206C78.5981 16.5506 78.3781 16.8106 78.0481 16.8106C77.7181 16.8106 77.4881 16.5506 77.4881 16.2206H77.4781ZM80.2181 21.8606L79.4581 19.6306C79.2881 19.1206 79.1681 18.6606 79.0581 18.2106H79.0381C78.9181 18.6706 78.7981 19.1406 78.6481 19.6106L77.8881 21.8506H80.2181V21.8606ZM79.6181 16.2206C79.6181 15.9006 79.8581 15.6406 80.1781 15.6406C80.4981 15.6406 80.7281 15.8906 80.7281 16.2206C80.7281 16.5506 80.5081 16.8106 80.1681 16.8106C79.8281 16.8106 79.6181 16.5506 79.6181 16.2206Z"
          fill="#231F20"/>
      <path
          d="M84.1172 17.4315C84.6272 17.3315 85.3472 17.2715 86.0372 17.2715C87.1072 17.2715 87.7972 17.4715 88.2772 17.9015C88.6672 18.2515 88.8872 18.7715 88.8872 19.3715C88.8872 20.3915 88.2472 21.0715 87.4272 21.3515V21.3815C88.0272 21.5915 88.3872 22.1415 88.5672 22.9415C88.8172 24.0215 89.0072 24.7715 89.1672 25.0715H88.1272C87.9972 24.8515 87.8272 24.1815 87.6072 23.2215C87.3772 22.1515 86.9672 21.7515 86.0572 21.7115H85.1172V25.0715H84.1172V17.4215V17.4315ZM85.1172 20.9615H86.1372C87.2072 20.9615 87.8872 20.3715 87.8872 19.4915C87.8872 18.4915 87.1572 18.0515 86.1072 18.0415C85.6272 18.0415 85.2772 18.0915 85.1172 18.1315V20.9615Z"
          fill="#231F20"/>
      <path
          d="M90.9631 17.4315C91.5731 17.3415 92.2931 17.2715 93.0931 17.2715C94.5331 17.2715 95.5531 17.6015 96.2331 18.2415C96.9231 18.8715 97.3231 19.7715 97.3231 21.0215C97.3231 22.2715 96.9331 23.3215 96.2031 24.0315C95.4731 24.7515 94.2831 25.1515 92.7731 25.1515C92.0631 25.1515 91.4631 25.1215 90.9531 25.0615V17.4315H90.9631ZM91.9631 24.2915C92.2131 24.3415 92.5831 24.3515 92.9731 24.3515C95.1131 24.3515 96.2731 23.1515 96.2731 21.0615C96.2831 19.2315 95.2531 18.0715 93.1331 18.0715C92.6131 18.0715 92.2231 18.1215 91.9631 18.1715V24.2915Z"
          fill="#231F20"/>
      <path d="M100.281 17.3203V25.0703H99.2812V17.3203H100.281Z" fill="#231F20"/>
      <path
          d="M108.579 24.73C108.129 24.89 107.249 25.16 106.199 25.16C105.029 25.16 104.059 24.86 103.299 24.14C102.629 23.5 102.219 22.46 102.219 21.25C102.229 18.94 103.819 17.25 106.419 17.25C107.319 17.25 108.019 17.45 108.349 17.61L108.109 18.43C107.699 18.25 107.179 18.1 106.399 18.1C104.509 18.1 103.279 19.27 103.279 21.22C103.279 23.17 104.459 24.35 106.269 24.35C106.929 24.35 107.369 24.26 107.599 24.14V21.83H106.019V21.02H108.569V24.75L108.579 24.73Z"
          fill="#231F20"/>
    </g>
    <defs>
      <clipPath id="clip0_3252_31813">
        <rect width="211" height="69" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'BankIdIcon'
}
</script>
