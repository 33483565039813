<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
    <path d="M8.01137 12.5222L9.0716 5.83952C8.65393 5.83952 7.93908 5.83952 7.93908 5.83952C7.40896 5.83952 6.72623 5.54233 6.52543 4.99615C6.46117 4.81141 6.30856 4.17687 7.18406 3.5584C7.49731 3.34153 7.69811 3.10057 7.73827 2.91583C7.77843 2.72306 7.73024 2.55439 7.5937 2.42587C7.40093 2.24114 7.02342 2.13672 6.54149 2.13672C5.73025 2.13672 5.15997 2.60258 5.10375 2.93993C5.06358 3.18892 5.25636 3.38973 5.42503 3.51824C5.93105 3.89575 6.05153 4.44193 5.73828 4.95599C5.417 5.4861 4.7182 5.83148 3.97122 5.83952C3.97122 5.83952 3.23227 5.83952 2.8146 5.83952C2.71821 6.49012 1.14392 16.466 1.02344 17.2531H7.27241C7.32864 16.9078 7.61779 15.0202 8.01137 12.5222Z" fill="#223C4E"/>
    <path d="M0.682729 19.5664H3.24497C4.33733 19.5664 4.60239 20.1206 4.52207 20.6266C4.45782 21.0363 4.17669 21.3415 3.69477 21.5423C4.30521 21.7752 4.54617 22.1367 4.45782 22.707C4.34537 23.4218 3.72689 23.9519 2.91565 23.9519H0L0.682729 19.5664ZM2.3775 21.3817C2.87549 21.3817 3.10842 21.1166 3.15661 20.8033C3.20481 20.466 3.0522 20.2331 2.55421 20.2331H2.11244L1.93574 21.3817H2.3775ZM2.10441 23.2692C2.61846 23.2692 2.91565 23.0604 2.98794 22.6347C3.04417 22.2652 2.83533 22.0483 2.33734 22.0483H1.83935L1.64658 23.2772H2.10441V23.2692Z" fill="#223C4E"/>
    <path d="M8.04591 23.984C7.37924 24.0322 7.05796 23.9599 6.89732 23.6707C6.5439 23.8876 6.15033 24 5.73266 24C4.97764 24 4.71259 23.6065 4.78487 23.1727C4.817 22.9639 4.93748 22.7631 5.13025 22.5944C5.54792 22.233 6.57603 22.1848 6.97764 21.9117C7.00977 21.6065 6.88928 21.494 6.51178 21.494C6.07001 21.494 5.70053 21.6386 5.066 22.0723L5.21861 21.0764C5.76479 20.6828 6.29491 20.498 6.90535 20.498C7.68446 20.498 8.37522 20.8193 8.24671 21.6707L8.0941 22.6346C8.03787 22.9719 8.05394 23.0763 8.43145 23.0844L8.04591 23.984ZM6.88928 22.4659C6.53587 22.6908 5.87724 22.6506 5.80495 23.1165C5.77282 23.3334 5.90937 23.494 6.12623 23.494C6.33507 23.494 6.5921 23.4057 6.80093 23.2611C6.78487 23.1808 6.7929 23.1004 6.817 22.9478L6.88928 22.4659Z" fill="#223C4E"/>
    <path d="M9.27209 20.5543H10.6054L10.5331 20.996C10.9588 20.6346 11.2801 20.498 11.6978 20.498C12.4448 20.498 12.7901 20.9559 12.6697 21.7029L12.3243 23.9438H10.991L11.2801 22.0884C11.3363 21.7511 11.2319 21.5904 10.9749 21.5904C10.7661 21.5904 10.5733 21.7029 10.3886 21.9519L10.0833 23.9358H8.75L9.27209 20.5543Z" fill="#223C4E"/>
    <path d="M13.7296 19.5664H15.0629L14.7256 21.719L16.0027 20.5544H17.6493L16.0107 22.0001L17.328 23.9439H15.6493L14.6372 22.3696H14.6212L14.3802 23.9359H13.0469L13.7296 19.5664Z" fill="#223C4E"/>
    <path d="M18.4638 19.5664H19.9979L19.3232 23.9439H17.7891L18.4638 19.5664Z" fill="#223C4E"/>
    <path d="M20.7374 19.5664H22.9302C24.625 19.5664 25.1149 20.7953 24.9543 21.8154C24.8017 22.8114 24.0145 23.9439 22.5286 23.9439H20.0547L20.7374 19.5664ZM22.1591 22.8997C22.9061 22.8997 23.3157 22.5303 23.4362 21.7511C23.5246 21.1728 23.3478 20.6025 22.5205 20.6025H22.1109L21.7575 22.8997H22.1591Z" fill="#223C4E"/>
    <path d="M16.4166 0H10.0311L9.17969 5.4056H10.264C10.8584 5.4056 11.4206 5.13251 11.6616 4.73894C11.7419 4.61043 11.7741 4.49798 11.7741 4.39356C11.7741 4.16866 11.6214 3.99999 11.4688 3.88754C11.0512 3.57428 10.9628 3.24497 10.9628 3.01204C10.9628 2.96385 10.9628 2.92368 10.9708 2.88352C11.0592 2.31324 11.8303 1.69477 12.8504 1.69477C13.4608 1.69477 13.9267 1.83935 14.2078 2.10441C14.4568 2.33734 14.5532 2.66666 14.4809 3.01204C14.3925 3.42168 13.9829 3.75902 13.75 3.9277C13.1315 4.36143 13.2118 4.73894 13.252 4.85139C13.3805 5.18874 13.8704 5.4056 14.2479 5.4056H15.9026C15.9026 5.4056 15.9026 5.4056 15.9026 5.41364C18.1515 5.4297 19.3564 6.46584 18.9789 8.87547C18.6254 11.1164 16.9066 12.0803 14.8584 12.0963L14.0471 17.269H15.2439C20.2961 17.269 24.4246 14.024 25.2359 8.89957C26.2399 2.53814 22.1997 0 16.4166 0Z" fill="#223C4E"/>
  </svg>
</template>

<script>
export default {
  name: 'BankIdIcon'
}
</script>
